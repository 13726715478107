import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {

    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [searchProductData, setSearchProductData] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const [searchName, setSearchNameValue] = useState('');
    const [searchId, setSearchIdValue] = useState('');
    const [searchNameMob, setSearchNameMobValue] = useState('');
    const [searchIdMob, setSearchIdMobValue] = useState('');

    const texts = ["Arivomkadaii", "அறிவோம்கடைi"];
    const delay = 300;
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [scroll, setScroll]= useState("");


    const [searchInput, setSearchInput] = useState('');
    const [searchProductDatas, setSearchProductDatas] = useState([]);
    
    const searchProducts = async (inputData) => {
        if (inputData.trim() !== '') {
            try {
                let response;
                if (!isNaN(inputData)) {
                    response = await axios.post('https://api.arivomkadai.com/search/', { product: inputData, from: 'product_code' });
                } else {
                    response = await axios.post('https://api.arivomkadai.com/search/', { product: inputData, from: 'product_name' });
                }
                setSearchProductDatas(response.data.data || []); 
              //  console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }else{
            setSearchProductDatas([]);
            setSearchInput('');
        }
    };

    const handleChange = (event) => {
        setSearchInput(event.target.value);
        const inputsValue = event.target.value;
        searchProducts(inputsValue);
    };
  
    useEffect(() => {
      const textToType = texts[currentIndex % texts.length];
      if (currentText !== textToType) {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => {
            const nextChar = textToType[prevText.length];
            return prevText + nextChar;
          });
        }, delay);
  
        return () => clearTimeout(timeout);
      } else {
        setCurrentIndex(prevIndex => prevIndex + 1);
        setCurrentText('');
      }
    }, [currentText, currentIndex, delay, texts]);


    const toggleSidebar = () => {
      setSidebarVisible(!isSidebarVisible); 
    };
    
    const openModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            if (scroll < 200) {
                setIsSticky(false);
                // Add logic to close dropdowns if needed
            } else {
                setIsSticky(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const searchByName = (e) => {
        setSearchNameValue(e.target.value);
        setSearchIdValue('');
        setSearchNameMobValue('');
        setSearchIdMobValue('');

    };

    const searchById = (e) => {
        setSearchIdValue(e.target.value);
        setSearchNameMobValue('');
        setSearchIdMobValue('');
        setSearchNameValue('');
    };

    const searchByNameMob = (e) => {
        setSearchNameMobValue(e.target.value);
        setSearchIdValue('');
        setSearchIdMobValue('');
        setSearchNameValue('');
    };

    const searchByIdMob = (e) => {
        setSearchIdMobValue(e.target.value);
        setSearchIdValue('');
        setSearchNameMobValue('');
        setSearchNameValue('');
    };

    useEffect(() => {
        if (searchName.length > 0) {

            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                document.body.classList.add('modal-open');
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/search/', {product: searchName, from: 'product_name'});
                        setSearchProductData(response.data);
                        //console.log(response.data);
                } catch (error) {
      
                    console.error("Error fetching data:", error);
                }
            };

            
            fetchSearchData();
        
        } else {
            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
            }
        }
      }, [searchName]);

      useEffect(() => {
        if (searchNameMob.length > 0) {

            const modal = document.getElementById('searchNameModalMob');
            if (modal) {
                modal.style.display = 'block';
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/search/', {product: searchNameMob, from: 'product_name'});
                        setSearchProductData(response.data);
                        //console.log(response.data);
                } catch (error) {
      
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();

        } else {
            const modal = document.getElementById('searchNameModalMob');
            if (modal) {
                modal.style.display = 'none';
            }
        }
      }, [searchNameMob]);



      useEffect(() => {
        if (searchId.length > 0) {

            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.add('show');
                modal.style.display = 'block';
                document.body.classList.add('modal-open');
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/search/', {product: searchId, from: 'product_code'});
                        setSearchProductData(response.data);
                        //console.log(response.data);
                } catch (error) {
      
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();

        } else {
            const modal = document.getElementById('searchNameModal');
            if (modal) {
                modal.classList.remove('show');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
            }
        }
      }, [searchId]);

      useEffect(() => {
        if (searchIdMob.length > 0) {

            const modal = document.getElementById('searchNameModalMobId');
            if (modal) {
                modal.style.display = 'block';
            }

            const fetchSearchData = async () => {
                try {
                    const response = await axios.post('https://api.arivomkadai.com/search/', {product: searchIdMob, from: 'product_code'});
                        setSearchProductData(response.data);
                        //console.log(response.data);
                } catch (error) {
      
                    console.error("Error fetching data:", error);
                }
            };

            fetchSearchData();

        } else {
            const modal = document.getElementById('searchNameModalMobId');
            if (modal) {
                modal.style.display = 'none';
            }
        }
      }, [searchIdMob]);



      const closeSearchNameModal = () => {
        setSearchIdMobValue('');
        setSearchIdValue('');
        setSearchNameMobValue('');
        setSearchNameValue('');
        const modal = document.getElementById('searchNameModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };
      

      useEffect(() => {
        const fetchScrollData = async () => {
          try {
            // Make API call to fetch old data
            const response = await axios.post(`https://api.arivomkadai.com//scrollonline/`);
            const scroll = response.data.data[0].content;
            setScroll(scroll); // Set oldData state
            //console.log("scroll:", scroll);
          } catch (error) {
            console.error('Error fetching old data:', error);
          }
        };
        // Call the fetchOldData function
        fetchScrollData();
      }, []);
    



    return (



<>
<div class="mobile-promotions">
<div class="scroll-left" >
  <div class="scrolling-container">
  <p>{scroll}</p>
    

  </div>
</div>
</div>

<div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Contact Us</h1>
                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                        </div>
                                        <form method="post">
										  <div class="row">
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input type="text" required="" name="name" placeholder="Enter your name..." />
                                              </div>
                                            </div>
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input required="" type="text" name="number" placeholder="Enter your WhatsApp Number..." />
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6">
											  <div class="form-group">
                                                <select required="" name="type">
												  <option selected hidden disabled>Select Purpose</option>
											      <option>Product or Service Promotion</option>
											      <option>Sell your products in our website</option>
											      <option>Product Enquiry for shopping</option>
                                                  <option>Others</option>
												</select>
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6" align="center">
                                              <div class="form-group">
                                                <input required="" type="text" name="socialmedia" placeholder="Enter your Social Media Links..." />
                                              </div>
                                            </div>
                                          </div>
											
											<div class="form-group">
                                                <textarea rows="2" name="message" placeholder="Enter your Queries..." ></textarea>
                                            </div>
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade custom-modal" id="searchNameModal" tabindex="-1" aria-labelledby="searchNameModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeSearchNameModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        
                                        {searchProductData && searchProductData.data && searchProductData.data.length > 0 ? (
                                            searchProductData.data.map(searchProduct => (
                                                <a className="product-link" href={`product?id=${searchProduct.p_id}`}>
                                                  <img src={`https://api.arivomkadai.com/product_data/${searchProduct.image}`} alt={searchProduct.product} className="product-image" />
                                                  <div className="product-details">
                                                    <p className="product-name">{searchProduct.product}</p>
                                                    {/* <p className="product-price">₹ {searchProduct.price}</p> */}
                                                  </div>
                                                </a>

                                               ))
                                            ) : (
                                            <h5 align="center">No products found.</h5>
                                        )}
                                    </div>
                                </div>
                                
                </div>
            </div>
        </div>
    </div>



      <header class="header-area header-style-1 header-height-2">
        <div class="header-middle header-middle-ptb-1 d-none d-lg-block" style={{background:'#fff'}}>
            <div class="container">
                <div class="header-wrap">
                    <div class="logo logo-width-1 col-3">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-right">
                        <div class="search-style-1">
                            <form action='javascript:void(0);' style={{zIndex: '3'}}>
                                <input type="text" value={searchName} onChange={searchByName} placeholder="Search for items by name..." />
                            </form>
                        </div>
                        <div class="search-style-1">
                            <form action='javascript:void(0);' style={{zIndex: '3'}}>
                                <input type="text" value={searchId} onChange={searchById} placeholder="Search for items by code..." />
                            </form>
                        </div>
                        <ul style={{display:'flex'}}>
                            <li style={{padding: '10px'}}><a href="/online-products">Home</a></li>
                            <li style={{padding: '10px'}}><a href="/about?id=1">About</a></li>
                            <li style={{padding: '10px'}}><a href="/contact?id=1">contact</a></li>
                        </ul>
                        
                        <div class="header-action-right">
                            <div class="header-action-2">
                               
                               
                                <div class="header-action-icon-2">
                                  <a id="openModalButton" onClick={openModal}><img src="assets/imgs/theme/icons/icon-headphone.svg"             alt="hotline"  /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-bottom header-bottom-bg-color sticky-bar" className={`sticky-bar ${isSticky ? 'stick' : ''}`} style={{paddingTop:'40px', paddingBottom:'20px'}}>
            <div class="container">
                <div class="header-wrap header-space-between position-relative">
                    <div class="logo logo-width-1 d-block d-lg-none">
                             <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-nav d-none d-lg-flex">
                        <div class="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                            <nav>
                                <ul>
                                    
                                    <li><a href='/category?id=Tech'>Deals</a></li>
                                    <li><a href='/category?id=Tech'>Tech</a></li>
                                    <li><a href='/category?id=Home'>Home Decor</a></li>
                                    <li><a href='/category?id=Kitchen'>Kitchen</a></li>
                                    <li><a href='/category?id=Kids'>Kids</a></li>
                                    <li><a href='/category?id=Gifts'>Gifts</a></li>
                                    <li><a href='/category?id=Personalcare'>Personal Care</a></li>
                                    <li><a href='/category?id=Health'>Health</a></li>
                                    <li><a href='/category?id=Automobile'>Automobile</a></li>
                                    <li><a href='/category?id=Fashions'>Fashions</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="header-action-icon-2 d-block d-lg-none">
                        <div class="burger-icon burger-icon-white" onClick={toggleSidebar}>
                            <span class="burger-icon-top"></span>
                            <span class="burger-icon-mid"></span>
                            <span class="burger-icon-bottom"></span>
                        </div>
                    </div>
                    <div class="header-action-right d-block d-lg-none">
                        <div class="header-action-2">
                            <div class="header-action-icon-2">
                            <a id="openModalButton" onClick={openModal}><img src="assets/imgs/theme/icons/icon-headphone.svg"             alt="hotline"  /></a>
                            </div>



                            <div class="header-action-icon-2">
                                    <a>
                                        <img class="svgInject" alt="Nest" src="assets/imgs/theme/icons/icon-search.png" />
                                    </a>
                                   
                                    <div class="cart-dropdown-wrap cart-dropdown-hm2 account-dropdowns">
                                    <input type="text" value={searchInput} onChange={handleChange} placeholder="Search for items by code or name..." />
            <div className="extraDiv" >
                {searchInput !== '' && (
                searchProductDatas.length > 0 ? (
                    <ul>
                        {searchProductDatas.map(searchProduct => (
                            <li key={searchProduct.p_id}>
                                <a href={`product?id=${searchProduct.p_id}`}>
                                    <h6 className="p-20 fs-14">{searchProduct.product}</h6>
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No products found.</p>
                ))}
            </div>
                                    </div>
                                </div>
                            
                        </div>
                    </div>

                  


                </div>
                <div class="mobile-promotions mobile-stick " style={{display:'none'}}>
                    <div class="scroll-left" >
                        <div class="scrolling-container">
                            <p>{scroll}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className={isSidebarVisible ? 'mobile-header-active mobile-header-wrapper-style sidebar-visible' : 'mobile-header-active mobile-header-wrapper-style'}>
        <div class="mobile-header-wrapper-inner">
            <div class="mobile-header-top">
                <div class="mobile-header-logo">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                </div>
                <div class="mobile-menu-close close-style-wrap close-style-position-inherit">
                    <button class="close-style search-close" onClick={toggleSidebar}>
                        <i class="icon-top"></i>
                        <i class="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div class="mobile-header-content-area">
                <div class="mobile-search search-style-3 mobile-header-border">
                <form action='javascript:void(0);'>
                     <input type="text" value={searchNameMob} onChange={searchByNameMob} placeholder="Search for items by name..." />
                     <div class ="extraDiv" id="searchNameModalMob"> 
                        <ul>
                                        {searchProductData && searchProductData.data && searchProductData.data.length > 0 ? (
                                            searchProductData.data.map(searchProduct => (
                                                <li>
                                                <a  href={`product?id=${searchProduct.p_id}`}><h6 class="p-20 fs-14">
                                                 {searchProduct.product} 
                                                 </h6></a>
                                               </li>
                                               ))
                                            ) : (
                                            <h5 class="p-20 fs-14" align="center">No products found.</h5>
                                        )}
                        </ul>
                    </div>
                </form>
                    
                </div>
                <div class="mobile-search search-style-3 mobile-header-border">
                    <form action='javascript:void(0);'>
                        <input type="number" value={searchIdMob} onChange={searchByIdMob} placeholder="Search for items by code..." />
                        <div class ="extraDiv" id="searchNameModalMobId"> 
                        <ul>
                                        {searchProductData && searchProductData.data && searchProductData.data.length > 0 ? (
                                            searchProductData.data.map(searchProduct => (
                                                <li>
                                                <a  href={`product?id=${searchProduct.p_id}`}><h6 class="p-20 fs-14">
                                                 {searchProduct.product} 
                                                 </h6></a>
                                               </li>
                                               ))
                                            ) : (
                                            <h5 class="p-20 fs-14" align="center">No products found.</h5>
                                        )}
                        </ul>
                    </div>
                    </form>
                </div>
                <div class="mobile-menu-wrap mobile-header-border">
                  
                    <nav>
                        <ul class="mobile-menu font-heading">
                            <li><a href='/online-products'>Home</a></li>
                            <li><a href='/about?id=1'>About</a></li>
                            <li><a href='/contact?id=1'>Contact</a></li>
                            <li><a href='/category?id=Tech'>Deals</a></li>
                                    <li><a href='/category?id=Tech'>Tech</a></li>
                                    <li><a href='/category?id=Home'>Home Decor</a></li>
                                    <li><a href='/category?id=Kitchen'>Kitchen</a></li>
                                    <li><a href='/category?id=Kids'>Kids</a></li>
                                    <li><a href='/category?id=Gifts'>Gifts</a></li>
                                    <li><a href='/category?id=Personalcare'>Personal Care</a></li>
                                    <li><a href='/category?id=Health'>Health</a></li>
                                    <li><a href='/category?id=Automobile'>Automobile</a></li>
                                    <li><a href='/category?id=Fashions'>Fashions</a></li>
                        </ul>
                    </nav>
                
                </div>
               
               
                <div class="site-copyright">© 2024  ArivomKadai All Rights Reserved. Websolutions By <a href="https://sninfoserv.com" target="_blank">SN Infoserv</a></div>
            </div>
        </div>
    </div>
</>

);
}

export default App;