import React, { useEffect, useState } from 'react';
import Header from './headerpages';
import Footer from './footer';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
ReactSession.setStoreType("localStorage");




function Processsucess() {
    const navigate = useNavigate();
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
   // console.log(orderId);

   const userId = ReactSession.get("arkuserId");
   const otpStatus = ReactSession.get("otp");
   if(userId > 0 && otpStatus == 'no'){
    navigate('/otp');
 }
    const fetchData = async () => {
      
       // console.log("user:",formData);
      try {
        const formData = {id:orderId};
        const response = await axios.post(`https://api.arivomkadai.com/processsucess/`, formData);
 
        setProcess(response.data);
        //console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
    fetchData();
    
    
    }, []); 



    const fetchpreorderData = async () => {
      
        // console.log("user:",formData);
       try {
         const formData = {id:orderId};
         const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
  
         setpreorderProcess(response.data);
        // console.log(response.data);
       } catch (error) {
         
         console.error("Error fetching data:", error);
       }
     };
     useEffect(() => {
        fetchpreorderData();
     
     
     }, []); 
    

  return (
    <>
    <div class="sidebars">
    <a  className="sidebars" href="/online-products">
      <h3 class="bar-content">Arivom Products </h3>
    </a>
</div>
<div class="main-bar">

  <Header  />

  <main class="main">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span> Sucess
                </div>
            </div>
        </div>
        <div class="page-header mt-25 mb-10">
            <div class="container">
                <div class="archive-header">
                    <div class="row align-items-center">
                    <div class="col-xl-12" style={{textAlignLast: "center"}}>
                      <h1 class="mb-5">Order Placed.</h1>
                      <h5 class="font-light">{orderId}</h5>
                    </div>
                    </div>
                </div>
            </div>
        </div>

       





        <div class="container mb-80 mt-50">



             {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder'?(
                <div class="row">
       
                <div class="col-lg-8">
                    
                    <div class="table-responsive shopping-summery">
                        <table class="table table-wishlist">
                            <thead>
                                <tr class="main-heading">
                                    <th class="custome-checkbox start pl-30">
                                       
                                    </th>
                                    <th scope="col" colspan="2">Product</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">qty</th>
                                    <th scope="col">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                            {preorderprocess && preorderprocess.data && preorderprocess.data.orderdetails && preorderprocess.data.orderdetails.map(product => (
                    <tr className="pt-30"key={product.id}>
                        <td className="custome-checkbox pl-30">
                          
                        </td>
                        <td className="image product-thumbnail pt-40">
                            <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product}/>
                        </td>
                        <td className="product-des product-name pr-50">
                            <h6 className="mb-5"><a className='product-name mb-10 text-heading' href={`offlineproduct?id=${product.id}`}>{product.product}</a></h6>
                        </td>
                        <td className="price" data-title="Price">
                            <h4 className="text-body">₹{product.price}</h4>
                        </td>
                        <td className="text-center detail-info" data-title="Stock">
                            <h4 className="text-body">x{product.quantity}</h4>
                        </td>
                        <td className="price" data-title="Price">
                            <h4 className="text-brand">₹{product.subtotal}</h4>
                        </td>
                    </tr>
                ))}
            </tbody>
            
            
                        </table>
                    </div>
                    <div class="divider-2 mb-30"></div>
                   
                </div>
                <div class="col-lg-4">
                    <div class="border p-md-4 cart-totals ml-30">
                   
                        <div class="table-responsive">
                            <table class="table no-border">
                            <tbody>
                 
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Subtotal</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹ {preorderprocess && preorderprocess.data && preorderprocess.data.total}.00</h4>
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Shipping</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-end">₹{preorderprocess && preorderprocess.data && preorderprocess.data.shipping}.00</h5>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Offer</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-end">₹{preorderprocess && preorderprocess.data && preorderprocess.data.offer || 0}.00</h5>
                                </td>
                            </tr>

                           

                            {preorderprocess && preorderprocess.data && preorderprocess.data.gift > 0 ? (<> <tr>
                             <td class="cart_total_label">
                             <h6 class="text-muted">GiftBox</h6>
                             </td>
                             <td class="cart_total_amount">
                                 <h4 class="text-brand text-end">₹{preorderprocess && preorderprocess.data && preorderprocess.data.gift}.00</h4>
                             </td>
                         </tr></>):(<></>)}  
                          
                         <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>


                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Total</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹{preorderprocess && preorderprocess.data && preorderprocess.data.grandtotal || 0}.00</h4>
                                </td>
                            </tr>
                        
                
                </tbody>
                            </table>
                        </div>
                         <a href={`/track?id=${orderId}`} class="btn mb-20 w-100">Track Order<i class="fi-rs-sign-out ml-15"></i></a>
                    </div>
                </div>
            </div>
            ):
            (
                <div class="row">
       
    <div class="col-lg-8">
        
        <div class="table-responsive shopping-summery">
            <table class="table table-wishlist">
                <thead>
                    <tr class="main-heading">
                        <th class="custome-checkbox start pl-30">
                           
                        </th>
                        <th scope="col" colspan="2">Product</th>
                        <th scope="col">Price</th>
                        <th scope="col">qty</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                {process && process.data && process.data.orderdetails && process.data.orderdetails.map(product => (
        <tr className="pt-30"key={product.id}>
            <td className="custome-checkbox pl-30">
              
            </td>
            <td className="image product-thumbnail pt-40">
                <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product}/>
            </td>
            <td className="product-des product-name pr-50">
                <h6 className="mb-5"><a className='product-name mb-10 text-heading' href={`offlineproduct?id=${product.id}`}>{product.product}</a></h6>
            </td>
            <td className="price" data-title="Price">
                <h4 className="text-body">₹{product.price}</h4>
            </td>
            <td className="text-center detail-info" data-title="Stock">
                <h4 className="text-body">x{product.quantity}</h4>
            </td>
            <td className="price" data-title="Price">
                <h4 className="text-brand">₹{product.subtotal}</h4>
            </td>
        </tr>
    ))}
</tbody>


            </table>
        </div>
        <div class="divider-2 mb-30"></div>
       
    </div>
    <div class="col-lg-4">
        <div class="border p-md-4 cart-totals ml-30">
       
            <div class="table-responsive">
                <table class="table no-border">
                <tbody>
     
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Subtotal</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h4 class="text-brand text-end">₹ {process && process.data && process.data.total}.00</h4>
                    </td>
                </tr>
                <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
              
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Shipping</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h5 class="text-heading text-end">₹{process && process.data && process.data.shipping}.00</h5>
                    </td>
                </tr>
              
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Offer</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h5 class="text-heading text-end">₹{process && process.data && process.data.offer || 0}.00</h5>
                    </td>
                </tr>
               
                {process && process.data && process.data.giftBox > 0 ? (<> <tr>
                             <td class="cart_total_label">
                                 <h6 class="text-muted">GiftBox</h6>
                             </td>
                             <td class="cart_total_amount">
                                 <h4 class="text-brand text-end">₹{process && process.data && process.data.giftBox}.00</h4>
                             </td>
                         </tr></>):(<></>)}


                         <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
              
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Total</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h4 class="text-brand text-end">₹{process && process.data && process.data.grandtotal || 0}.00</h4>
                    </td>
                </tr>
            
    
    </tbody>
                </table>
            </div>
             <a href={`/track?id=${orderId}`} class="btn mb-20 w-100">Track Order<i class="fi-rs-sign-out ml-15"></i></a>
        </div>
    </div>
</div>
            )}
        

</div>
    </main>

  <Footer />

  </div>
  </>
  )
}

export default Processsucess;