import React, { useEffect, useState} from 'react';
import Header from './headerpages';
import Footer from './footeroffline';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
import Footeroffline from './footeroffline';
ReactSession.setStoreType("localStorage");

function Wishlist() {
    const navigate = useNavigate();
    const userId = ReactSession.get("arkuserId");
    const otpStatus = ReactSession.get("otp");
    const [Wishlistdata, setWishlistdata] = useState(null);
    const [quantity, setQuantity] = useState(1);
     

    if(userId > 0 && otpStatus == 'no'){
        navigate('/test');
     }
    
    const fetchData = async () => {
        const formData = {
            id: userId, 
        };
      try {
        const response = await axios.post('https://api.arivomkadai.com/wishlist/', formData);
        setWishlistdata(response.data);
       //console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {   
      fetchData();
    }, []);

const removeProducts = async (productId) => {
    //console.log(productId);
    
    try {
        const response = await axios.post('https://api.arivomkadai.com/deletewish/', { id: productId });
         fetchData();
       //  console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
  };


  const addToCart = async (productIds) => {
   // console.log(productIds);
    // Get the existing cart items from session storage
    const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];

    // Check if the product is already in the cart
   
    const existingItemIndex = existingCartItems.findIndex(item => item.id === productIds);
    const product = Wishlistdata && Wishlistdata.data.filter(items => items.id === productIds);
    const quantity = 1; // Assuming quantity is defined somewhere

    if (existingItemIndex !== -1) {
        // If the product is already in the cart, update its quantity
        existingCartItems[existingItemIndex].quantity += quantity;
    } else {
        // If the product is not in the cart, add it as a new item
        const newItem = {
            id:  product[0].p_id,
            product: product[0].product,
            price: product[0].price,
            quantity: quantity,
            image: product[0].image
        };

        // Add the new item to the cart
        existingCartItems.push(newItem);
    }

    // Store the updated cart items back in session storage
    sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    alert(`${product[0].product} added to cart!`);

    // Remove the item from the wishlist
    try {
        const response = await axios.post('https://api.arivomkadai.com/deletewish/', { id: productIds });
        fetchData();
    } catch (error) {
        console.error("Error deleting item from wishlist:", error);
    }
};


  return (
    <>
  
     <div className="sidebars" >
     <a  className="sidebars-on" href="/online-products">
          <h3 className="bar-content">Online Products</h3>
          </a>
      </div>
      
  <div class="main-bar">
  
    <Header  />
  
    <main class="main">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a> <span></span> Wishlist
                </div>
            </div>
        </div>
        <div class="container mb-30 mt-50">
       <div class="row">
<div class="col-xl-10 col-lg-12 m-auto">
    <div class="mb-50">
        <h1 class="heading-2 mb-10">Your Wishlist</h1>
      
    </div>

    {Wishlistdata && Wishlistdata.count === 0 ? (
        <div class="col-lg-8 mb-40">
     
        <h6 class="text-body"> Please add items to your wishlist.</h6>
        <div class="d-flex justify-content-between mt-5">
        <div class="cart-action d-flex justify-content-between" >
            <a href="/offline" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
        </div>
              </div>
    </div>
    
):(
    <div class="table-responsive shopping-summery">
        <table class="table table-wishlist">
            <thead>
                <tr class="main-heading">
                    <th class="custome-checkbox start pl-30">
                       
                    </th>
                    <th scope="col" colspan="2">Product</th>
                    <th scope="col" class="centre">Remove</th>
                </tr>
            </thead>
            <tbody>
            {Wishlistdata && Wishlistdata.data && Wishlistdata.data.map(product=> (
                <tr class="pt-30" key={product.id}>
                    
                    <td class="image product-thumbnail pt-40"><a  href={`/offlineproduct?id=${product.p_id}`}><img src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product}/></a></td>
                    <td class="product-des product-name">
                        <h6><a class='product-name mb-10' href={`/offlineproduct?id=${product.p_id}`}>{product.product}</a></h6>
                        
                    </td>
                    <td class="action text-end" data-title="Remove">
                        <a class="text-body" onClick={() => removeProducts(product.id)}><i class="fi-rs-trash ml-20"></i></a>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
    )}
</div>
</div>
            
        </div>
    </main>
  
      <Footeroffline />
  
      </div>
      </>
  )
}
export default  Wishlist;