import React from 'react';
import Header from './headerpage';
import Footer from './footer';
import Slider from './slider';


function Privacy () {

    const openModal = () => {
        const modal = document.getElementById('onloadModal');
       
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
           
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('onloadModal');
       
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
           
            document.body.classList.remove('modal-open');
        }
    };
    return (
        <>

<div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Contact Us</h1>
                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                        </div>
                                        <form method="post">
										  <div class="row">
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input type="text" required="" name="name" placeholder="Enter your name..." />
                                              </div>
                                            </div>
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input required="" type="text" name="number" placeholder="Enter your WhatsApp Number..." />
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6">
											  <div class="form-group">
                                                <select required="" name="type">
												  <option selected hidden disabled>Select Purpose</option>
											      <option>Product or Service Promotion</option>
											      <option>Sell your products in our website</option>
											      <option>Product Enquiry for shopping</option>
                            <option>Others</option>
												</select>
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6" align="center">
                                              <div class="form-group">
                                                <input required="" type="text" name="socialmedia" placeholder="Enter your Social Media Links..." />
                                              </div>
                                            </div>
                                          </div>
											
											<div class="form-group">
                                                <textarea rows="2" name="message" placeholder="Enter your Queries..." ></textarea>
                                            </div>
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div> 


        <div class="sidebars">
          <a href="#">
            <h3 class="bar-content">Arivom Products</h3>
          </a>
      </div>
      <div class="main-bar">
    
        <Header  />
        <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                     <span></span> Privacy Policy
                </div>
            </div>
        </div>
        <div class="page-content pt-50">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 col-lg-12 m-auto">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="single-page pr-30 mb-lg-0 mb-sm-5">
                                    <div class="single-header style-2">
                                        <h2>Privacy Policy</h2>
                                    </div>
                                    <div class="single-content mb-50">
                                        <h4>Welcome to Arivomkadai’s Privacy Policy</h4>
                                       
                                        <p>
                                            This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.arivomkadai.com
                                        .</p>
                                       
                                        <h4>WHAT PERSONAL INFORMATION WE COLLECT</h4>
                                            <p>When you visit the Site, we collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.</p>
                                            <p>Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically collected information as <strong>Device Information.</strong> </p>


                                        <h4>We collect Device Information using the following technologies:</h4>
                                            <li><strong>Cookies </strong> We use data collection devices such as "cookies" on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal data. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. </li>
                                            <li><strong>Log files </strong>track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                                            <li>Also, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your Name, Phone Number, Email ID, billing address, shipping address, payment information (including Credit Card numbers, Debit Card numbers).  This is called<strong>  Order Information.</strong></li>
                                            <p><li>By<strong> Personal Information </strong>in this Privacy Policy, we are talking both about Device Information and Order Information.</li></p>
                                            
                                            
                                            
                                        <h4>HOW DO WE USE YOUR PERSONAL INFORMATION</h4>
                                            <p>
                                            We use the Order Information that we collect generally to fulfil any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).
                                            </p>
                                            
                                        <h4>Additionally, we use this Order Information to:</h4>
                                        
                                            <li>Communicate with you. </li>
                                            <li>Screen our orders for potential risk or fraud.</li>
                                            <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                                            <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site.</p>

                                        <h4>SHARING YOUR PERSONAL INFORMATION</h4>
                                        <p>We share your Personal Information with third parties to help us use your Personal Information, as described above.</p>
                                        <li>We also use Google Analytics to help us understand how our customers use (Store Name). How Google uses your Personal Information.</li>
                                        <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our rights. </p>
                                       

                                        <h4>BEHAVIOURAL ADVERTISING</h4>
                                        <p>We use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. </p>
                                        <p>External services such as:</p>
                                        <li> Facebook</li>
                                        <li> Google</li>
                                        <li> Twitter (x.com)</li>
                                        <li> Instagram</li>
                                        <p>You can opt out of targeted advertising…</p>

                                        <h4>SECURITY PRECAUTIONS </h4>

                                        <p>
                                        We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to our security guidelines to protect it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.
                                        </p>
                                        


                                        <h4>CHILDREN INFORMATION</h4>
                                        <p>Use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. We do not knowingly solicit or collect personal data from children under the age of 18 years. If you have shared any personal data of children under the age of 18 years, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy.</p>
                                        


                                        <h4>DATA RETENTION</h4>
                                        <p> We retain your personal data in accordance with applicable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you if we believe it may be necessary to prevent fraud or future abuse, to enable Arivomkadai to exercise its legal rights and/or defend against legal claims or if required by law or We may continue to retain your data in anonymised form for analytical and research purposes.</p>


                                        <h4> YOUR RIGHTS</h4>
                                       
                                       <p> We take every reasonable step to ensure that your personal data that we process is accurate and, where necessary, kept up to date, and any of your personal data that we process that you inform us is inaccurate (having regard to the purposes for which they are processed) is erased or rectified. You may access, correct, and update your personal data directly through the functionalities provided on the Platform. You may delete certain non-mandatory information by logging into our website and visiting Profile and Settings sections. You can also write to us at the contact information provided below to assist you with these requests. </p>
                                       <p>You have an option to withdraw your consent that you have already provided by writing to us at the contact information provided below. Please mention “for withdrawal of consent” in the subject line of your communication. We will verify such requests before acting upon your request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related Terms of Use and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, such withdrawal may hamper your access to the Platform or restrict provision of our services to you for which we consider that information to be necessary.</p>
                                       
                                        <h4>YOUR CONSENT</h4>

                                        <li>By visiting our Platform or by providing your personal data, you consent to the collection, use, storage, disclosure and otherwise processing of your personal data on the Platform in accordance with this Privacy Policy. If you disclose to us any personal data relating to other people, you represent that you have the authority to do so and to permit us to use the data in accordance with this Privacy Policy.</li>
                                        <p>You, while providing your personal data over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties like credit bureaus ) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.</p>
                                        




                                        <h4> CHANGES TO THIS PRIVACY POLICY</h4>
                                        <p>Please check our Privacy Policy periodically for changes . We may update this Privacy Policy to reflect changes to our information practices. We will alert you to significant changes by posting the date our policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.</p>
                               


                                       
                                        <h6>Grievance Officer</h6>
                                        <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>
                                        <p><strong>Name: Mrs. Jayashree</strong></p>
                                        <p><strong>Designation: Chief Marketing Head</strong></p>
                                        <p><strong>Arivomkadai.com </strong></p>
                                        <p><strong>Address: 5D, R.P.Jaya Paradise
                                            Chitra Nagar, Opposite to Amman kovil
                                            Saravanampatti
                                            Coimbatore,
                                            Tamilnadu, India, 641035.</strong></p>
                                        <p><strong>Email: arivomkadaiofficial@gmail.com</strong></p>
                                        <p>Customer Support</p>
                                        <li> If you have a query, concern, or complaint in relation to collection or usage of your personal data under this Privacy Policy, please contact us at arivomkadaiofficial@gmail.com</li>
                                        <li>You can reach our customer support team to address any of your queries or complaints related to product and services by clicking the link, selecting your order and choosing 'Need Help' option: <a class="text-primary" onClick={openModal} >https://arivomkadai.com/support </a></li>


            
                                        
                                    </div>
                                </div>   
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
       
        <Footer />
    
        </div>
        </>
    );
}
export default Privacy;