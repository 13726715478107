import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
ReactSession.setStoreType("localStorage");



 function Otp() {
    const navigate = useNavigate();
    const Email = ReactSession.get("email");
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [otp, setOtp] = useState('');

   const  getWhatsapp=()=>{
       const emailDiv = document.getElementById('email');
       const waDiv = document.getElementById('whatsapp');

       emailDiv.classList.add('hide');
       waDiv.classList.remove('hide');

    } ;

    const sendEmailVerification = async () => {
        try {
            const response = await axios.post('https://api.arivomkadai.com/verification/', Email);
            const result = response.data;
            //console.log(result);
        } catch (error) {
            console.error('Mail not Sent:', error);
        }
    };


    const sendWhatsappOTP = async () => {
        const formData = {
            email:Email,
            number: whatsappNumber, 
        }
        try {
            // Make API call to send OTP to WhatsApp number
            const response = await axios.post('https://api.arivomkadai.com/whatsapp/', formData);
            //console.log(response.data);
            // Handle response from WhatsApp API
        } catch (error) {
            console.error('Failed to send OTP to WhatsApp:', error);
        }
    };

    const verifyOTP = async () => {
        const formData = {
            email:Email,
            otp: otp
        }
        try {
            // Make API call to verify OTP
            const response = await axios.post('https://api.arivomkadai.com/verify/', formData);
            
          //  console.log(response.data);
            if(response.data.otp == 'no'){
              ReactSession.set("otp", response.data.otp);
              ReactSession.set("email", Email);
            }else{
              ReactSession.set("otp", response.data.otp);
              ReactSession.set("arkuserId", response.data.data[0].user_id);
              ReactSession.set("userName", response.data.data[0].name);
              ReactSession.set("email", response.data.data[0].email);
              ReactSession.set("phonenumber", response.data.data[0].phonenumber);
              ReactSession.set("gender", response.data.data[0].gender);
              ReactSession.set("profile", response.data.data[0].profile);
              navigate('/checkout');
            }
        } catch (error) {
            console.error('OTP Verification Failed:', error);
        }
    };



    const getWhatsappOtp =() =>{

        const waDiv = document.getElementById('whatsapp');
        const otpDiv = document.getElementById('whatsappotp');
        
        waDiv.classList.add('hide');
        otpDiv.classList.remove('hide');


        sendWhatsappOTP();

    }
  return (
    <main class="main pages">
    <div class="page-content  pb-150">
        <div class="container">
            <div class="row" align="center" style={{marginTop:'40vh'}}>
                <div class="col-xl-4 col-lg-6 col-md-12 m-auto" id="email">
                    <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder='Enter OTP sent to your Email'/>
                    <button onClick={verifyOTP} class="btn btn-heading btn-block hover-up mt-15">Verify</button><br></br>
                    <button onClick ={getWhatsapp}  class="btn btn-fill-out btn-block hover-up font-weight-bold mt-15">Get OTP in WhatsApp</button>                       
                </div>

                <div class="col-xl-4 col-lg-6 col-md-12 m-auto hide" id="whatsapp">
                    <input type="text"    value={whatsappNumber} onChange={(e) => setWhatsappNumber(e.target.value)} placeholder='Enter your WhatsApp Number'/>
                    <button onClick ={getWhatsappOtp} class="btn btn-heading btn-block hover-up mt-15">Get OTP</button><br></br>
                </div>


                <div class="col-xl-4 col-lg-6 col-md-12 m-auto hide" id="whatsappotp">
                    <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder='Enter OTP shared to your WhatsApp Number'/>
                    <button onClick={verifyOTP} class="btn btn-heading btn-block hover-up mt-15">Verify</button><br></br>
                </div>
           </div>
        </div>   
    </div>
    </main>
  )
}
export default Otp;
