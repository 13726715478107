import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';






function Footeroffline() {
    const texts = ["Arivomkadaii", "அறிவோம்கடைi"];
    const delay = 300;
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const textToType = texts[currentIndex % texts.length];
      if (currentText !== textToType) {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => {
            const nextChar = textToType[prevText.length];
            return prevText + nextChar;
          });
        }, delay);
  
        return () => clearTimeout(timeout);
      } else {
        setCurrentIndex(prevIndex => prevIndex + 1);
        setCurrentText('');
      }
    }, [currentText, currentIndex, delay, texts]);

    const openModal = () => {
        const modal = document.getElementById('onloadModal');
       
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
           
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('onloadModal');
       
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
           
            document.body.classList.remove('modal-open');
        }
    };
   
    return (

    <footer class="main">


<div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Contact Us</h1>
                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                        </div>
                                        <form method="post">
										  <div class="row">
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input type="text" required="" name="name" placeholder="Enter your name..." />
                                              </div>
                                            </div>
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input required="" type="text" name="number" placeholder="Enter your WhatsApp Number..." />
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6">
											  <div class="form-group">
                                                <select required="" name="type">
												  <option selected hidden disabled>Select Purpose</option>
											      <option>Product or Service Promotion</option>
											      <option>Sell your products in our website</option>
											      <option>Product Enquiry for shopping</option>
                            <option>Others</option>
												</select>
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6" align="center">
                                              <div class="form-group">
                                                <input required="" type="text" name="socialmedia" placeholder="Enter your Social Media Links..." />
                                              </div>
                                            </div>
                                          </div>
											
											<div class="form-group">
                                                <textarea rows="2" name="message" placeholder="Enter your Queries..." ></textarea>
                                            </div>
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div>
        {/* <section className="home-slider1 position-relative mb-30 mt-50">
            <div className="container">
                <div className="home-slide-cover1 mt-30">
                    <div className="hero-slider-11 style-4 dot-style-1 dot-style-1-position-1">
                        <OwlCarousel className='owl-theme' loop margin={10} items={1} nav>
                            <div className="single-hero-slider1 single-animation-wrap" style={{backgroundImage: "url(assets/imgs/slider/slider-1.png)"}}>
                                <div className="slider-content">
                                    <h1 className="display-1 mb-40">
                                        Online<br />
                                        Shopping
                                    </h1>
                                </div>
                            </div>
                            <div className="single-hero-slider single-animation-wrap" style={{backgroundImage: "url(assets/imgs/slider/slider-1.png)"}}>
                                <div className="slider-content">
                                    <h1 className="display-1 mb-40">
                                        Offline<br />
                                        Shopping
                                    </h1>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="slider-arrow hero-slider-1-arrow"></div>
                </div>
            </div>
        </section> */}
        


        <section class="section-padding footer-mid">
            <div class="col mb-53">
                        <div class="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                            <div class="logo mb-30">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                                <p class="font-lg text-heading">We Make Your Shopping Simple.</p>
                            </div>
                           
                        </div>
                    </div>
  
                    <div class="row">
                      <div class="col-xl-2 col-lg-2 col-md-6 mb-52">
                        <li><a href="/about">About Us</a></li>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-6 mb-52">
                        <li><a href="/privacy">Privacy Policy</a></li>
                        </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 mb-52">
                        <li><a href="/terms">Terms &amp; Conditions</a></li>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 mb-52">
                        <li style={{color:'#3BB77E'}}><a onClick={openModal}>Become a Vendor</a></li>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-6 mb-52"> 
                        <li style={{color:'#3BB77E', paddingRight:'5px'}}><a onClick={openModal}>Support</a></li>
                      </div>
                    </div>
                 
                            
                
        </section>
        <div class="container pb-30 wow animate__animated animate__fadeInUp" data-wow-delay="0">
            <div class="row align-items-center">
                <div class="col-12 mb-30">
                    <div class="footer-bottom"></div>
                </div>
                <div class="col-xl-8 col-lg-6 col-md-6">
                    <p class="font-sm mb-0">&copy; 2024, <strong class="text-brand">ArivomKadai</strong> All Rights Reserved. Websolutions By <a href="https://sninfoserv.com" target="_blank">SN Infoserv</a></p>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                    <div class="mobile-social-icon">
                        <h6>Follow Us</h6>
                        <a href="https://www.facebook.com/arivomkadaioffi"><img src="assets/imgs/theme/icons/icon-facebook-white.svg" alt="" /></a>
                        <a href="https://twitter.com/arivomkadaioffi"><img src="assets/imgs/theme/icons/icon-twitter-white.svg" alt="" /></a>
                        <a href="https://www.instagram.com/arivomkadaiofficial/"><img src="assets/imgs/theme/icons/icon-instagram-white.svg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    );
}

export default Footeroffline;