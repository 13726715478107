import React, { useEffect, useState } from 'react';


function App() {
    const texts = ["Arivomkadaii", "அறிவோம்கடைi"];
    const delay = 300;
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const textToType = texts[currentIndex % texts.length];
      if (currentText !== textToType) {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => {
            const nextChar = textToType[prevText.length];
            return prevText + nextChar;
          });
        }, delay);
  
        return () => clearTimeout(timeout);
      } else {
        setCurrentIndex(prevIndex => prevIndex + 1);
        setCurrentText('');
      }
    }, [currentText, currentIndex, delay, texts]);
   

    const openModal = () => {
        const modal = document.getElementById('onloadModal');
        const modBut = document.getElementById('openPopup');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            modBut.style.display = 'none';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('onloadModal');
        const modBut = document.getElementById('openPopup');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            modBut.style.display = 'block';
            document.body.classList.remove('modal-open');
        }
    };
    

    return (

<>

<div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Contact Us</h1>
                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                        </div>
                                        <form method="post">
										  <div class="row">
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input type="text" required="" name="name" placeholder="Enter your name..." />
                                              </div>
                                            </div>
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input required="" type="text" name="number" placeholder="Enter your WhatsApp Number..." />
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6">
											  <div class="form-group">
                                                <select required="" name="type">
												  <option selected hidden disabled>Select Purpose</option>
											      <option>Product or Service Promotion</option>
											      <option>Sell your products in our website</option>
											      <option>Product Enquiry for shopping</option>
                            <option>Others</option>
												</select>
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6" align="center">
                                              <div class="form-group">
                                                <input required="" type="text" name="socialmedia" placeholder="Enter your Social Media Links..." />
                                              </div>
                                            </div>
                                          </div>
											
											<div class="form-group">
                                                <textarea rows="2" name="message" placeholder="Enter your Queries..." ></textarea>
                                            </div>
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div>

    <header class="header-height-4">
        <div class="header-bottom header-bottom-bg-color sticky-bar">
            <div class="container">
                <div class="header-wrap header-space-between position-relative">
                    <div class="logo logo-width-1 d-block d-lg-none" >
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-nav d-none d-lg-flex col-lg-6">
                        <div class="main-categori-wrap d-none d-lg-block">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type={currentText}>
                              <span class="wrap">{currentText}</span>
                              </a>
                            </h4>
                        </div>
                       
                    </div>
                    
                   
                    
                </div>
            </div>
        </div>
    </header>
     

        <div class="floating-wpp"></div>
		
        <a id="openPopup" class="float1"  onClick={openModal}>
          <img class="my-float1" src="assets/imgs/theme/icons/icon-headphone.svg" alt="hotline"  />
        </a>
       
</>

);
}

export default App;