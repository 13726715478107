import React, { useState, useEffect } from 'react'
import Header from './headerpages';
import Footer from './footeroffline';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
import Footeroffline from './footeroffline';
ReactSession.setStoreType("localStorage");


 function Account() {
    const navigate = useNavigate();
    if(!ReactSession.get("arkuserId")){
        ReactSession.set("arkuserId","0");
    }
  const userId = ReactSession.get("arkuserId");
  const otpStatus = ReactSession.get("otp");
   
  const userName = ReactSession.get("userName");
  const Email = ReactSession.get("email");
  const phonenumber = ReactSession.get("phonenumber");
 const Gender = ReactSession.get("gender");
  const Profile = ReactSession.get("profile");
  const Building = ReactSession.get("building");
  const Street = ReactSession.get("street");
  const City = ReactSession.get("city");
 const Pincode = ReactSession.get("pincode");
  const State = ReactSession.get("state");


  const [order, setOrder]=useState([]);
  const [preorder, setpreOrder]=useState([]);
  const [error, setError]=useState({});
  const [Wishlistdata, setWishlistdata] = useState(null);
const [quantity, setQuantity] = useState(1);
const [address, setAddress] = useState({});
const [addresses, setAddresses] = useState({});
const [profilename, setprofilename] = useState('');
const [profileemail, setprofileemail] = useState('');
const [profilenumber, setprofilenumber] = useState('');
const [profilegender, setprofilegender] = useState('');
const [profileimage, setprofileimage] = useState(null);
const [profileimgname, setprofileimgname] = useState(Profile);
const [profilebuilding, setBuilding] = useState(null);
const [profilestreet, setStreet] = useState(null);
const [profilecity, setCity] = useState(null);
const [profilestate, setState] = useState(null);
const [profilepincode, setPincode] = useState(null);
const [selectedAddressId, setSelectedAddressId] = useState(null);

//console.log(profileimgname);
    if(userId > 0 && otpStatus === 'no'){
       navigate('/otp');
    }
    if(userId  === '0' || userId  === '' || userId  === null || !userId){
        navigate('/login');
     }

useEffect(() => {
    const name = userName;
    const email = Email;
    const number= phonenumber;
    const gender= Gender;
    const building = Building;
    const street = Street;
    const city = City;
    const state = State;
    const pincode = Pincode;
    const profile = Profile;
    
   

    setprofilename(name);
    setprofileemail(email);
    setprofilenumber(number);
    setprofilegender(gender);
    setprofileimage(profile);
    setBuilding(building);
    setStreet(street);
    setCity(state);
    setState(city);
    setPincode(pincode);
    

  }, []);

  const handleprofileName = (event) => {
    setprofilename(event.target.value);
  }
  const handleprofileEmail = (event) => {
    setprofileemail(event.target.value);
  }
  const handleprofileNumber = (event) => {
    setprofilenumber(event.target.value);
  }
  const handlebuilding = (event) => {
    setBuilding(event.target.value);
  }
  const handlestreet = (event) => {
    setStreet(event.target.value);
  }
  const handlestate = (event) => {
    setState(event.target.value);
  }
  const handlecity = (event) => {
    setCity(event.target.value);
  }
  const handlepincode = (event) => {
    setPincode(event.target.value);
  }
  const handleprofileGender = (event) => {
    setprofilegender(event.target.value);
  }


  const handleFileChange = (event) => {
   
    const file = event.target.files[0];
    const filename =event.target.files[0].name;
    setprofileimage(file);
    setprofileimgname(filename);
  }

  const handleprofileSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('id', userId);
    
  
    // Check if content has changed
    formData.append('name', profilename);
    formData.append('email', profileemail);
    formData.append('number', profilenumber);
    formData.append('gender', profilegender);
    formData.append('images', profileimage);
    formData.append('building', profilebuilding);
    formData.append('street', profilestreet);
    formData.append('state', profilestate);
    formData.append('city', profilecity);
    formData.append('pincode', profilepincode);
      
        

    try {
      const response = await axios.post('https://api.arivomkadai.com/editprofile/', formData);
     // console.log(response.data)
     ReactSession.set("userName", response.data.data[0].name);
     ReactSession.set("email", response.data.data[0].email);
     ReactSession.set("phonenumber", response.data.data[0].phonenumber);
     ReactSession.set("gender", response.data.data[0].gender);
     ReactSession.set("profile", response.data.data[0].profile);
     ReactSession.set("building", response.data.data[0].building);
     ReactSession.set("street", response.data.data[0].street);
     ReactSession.set("city", response.data.data[0].city);
     ReactSession.set("state", response.data.data[0].state);
     ReactSession.set("pincode", response.data.data[0].pincode);
      navigate('/account');
      alert("profile changes updatd successfully.")
    } catch (error) {
      console.error('There was a problem with login:', error);
      setError('Invalid email or password');
    }
  }



    



//orders
  const fetchData = async () => {
    const formData = {
        id: userId, 
    };
   // console.log("user:",formData);
  try {
    const response = await axios.post('https://api.arivomkadai.com/orders/', formData);
    setOrder(response.data);
   // console.log("orders:", response.data);
  } catch (error) {
    
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
fetchData();
}, []);





//preorders
const fetchDatas = async () => {
    const formData = {
        id: userId, 
    };
   // console.log("user:",formData);
  try {
    const response = await axios.post('https://api.arivomkadai.com/preorders/', formData);
    setpreOrder(response.data);
   //console.log("orders:", response.data);
  } catch (error) {
    
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
fetchDatas();
}, []);




//wishlist

const fetchwishData = async () => {
        const formData = {
            id: userId, 
        };
      try {
        const response = await axios.post('https://api.arivomkadai.com/wishlist/', formData);
        setWishlistdata(response.data);
      // console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {   
        fetchwishData();
    }, []);

const removeProducts = async (productId) => {
   // console.log(productId);
    
    try {
        const response = await axios.post('https://api.arivomkadai.com/deletewish/', { id: productId });
        fetchwishData();
        // console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
  };


  const addToCart = async (productIds) => {
   // console.log(productIds);
    // Get the existing cart items from session storage
    const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];

    // Check if the product is already in the cart
   
    const existingItemIndex = existingCartItems.findIndex(item => item.id === productIds);
    const product = Wishlistdata && Wishlistdata.data.filter(items => items.id === productIds);
    const quantity = 1; // Assuming quantity is defined somewhere

    if (existingItemIndex !== -1) {
        // If the product is already in the cart, update its quantity
        existingCartItems[existingItemIndex].quantity += quantity;
    } else {
        // If the product is not in the cart, add it as a new item
        const newItem = {
            id:  product[0].p_id,
            product: product[0].product,
            price: product[0].price,
            quantity: quantity,
            image: product[0].image
        };

        // Add the new item to the cart
        existingCartItems.push(newItem);
    }

    // Store the updated cart items back in session storage
    sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    alert(`${product[0].product} added to cart!`);

    // Remove the item from the wishlist
    try {
        const response = await axios.post('https://api.arivomkadai.com/deletewish/', { id: productIds });
        fetchwishData();
    } catch (error) {
        console.error("Error deleting item from wishlist:", error);
    }
};

//address


   //address
   const handleAddChange=(event)=>{
    const name = event.target.name;
    const value =event.target.value;
    setAddress(values => ({...values, [name]: value}))
}

const handleAddSubmit =async(event)=>{
    
    event.preventDefault();
    // console.log(address);
    const formData = {
        id: userId, // Include the userId in the form data
        ...address // Spread other address fields if they are separate
    };
    //console.log(formData);
    try {
        const response = await axios.post(`https://api.arivomkadai.com/addaddress/`, formData);
        fetchAddressData();
        handleCheckboxClick();
       // console.log("address:", response.data);
       // alert(`Address Added successfully`);
    } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Address Not Inserted');
    }
}




const fetchAddressData = async () => {
    const formData = {
        id: userId
    };
   // console.log("user:",formData);
  try {
    const response = await axios.post('https://api.arivomkadai.com/address/', formData);
    if(response.data.status === 'success'){
        setAddresses(response.data);
    }
   
   // console.log("addresses:", response.data);
  } catch (error) {
    
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
fetchAddressData();


}, []);



const handleCheckboxClick = () => {
    const collapseAddress = document.getElementById('collapseAddress');
    collapseAddress.classList.remove('show');
};


  //select address
  




const removeAddress = async (AddressId) => {
    //console.log(AddressId);
    const AddressData = { id: AddressId }
    try {
        const response = await axios.post('https://api.arivomkadai.com/deleteaddress/', AddressData);
       // setAddresses(response.data);
        //navigate('/account'); 
       // console.log( response.data);
        fetchAddressData();
       // alert(`Address deleted successfully`);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };
    //logout
    const handleLogout = () => {
        ReactSession.set("arkuserId", "0");
        ReactSession.set("userName", "");
        ReactSession.set("email", "");
        ReactSession.set("profile", "");
        ReactSession.set("phonenumber", "");
        ReactSession.set("gender", "");
        ReactSession.set("building", "");
        ReactSession.set("street", "");
        ReactSession.set("city", "");
        ReactSession.set("state", "");
        ReactSession.set("pincode", "");

        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('preorder');
        sessionStorage.removeItem('offer');
        sessionStorage.removeItem('preorderoffer');
        
        sessionStorage.removeItem('deliveryAmount');
        // Perform any other cleanup tasks or redirection if needed
     navigate('/login'); // Redirect to login page after logout
    }

    const reversedOrders = order && order.data ? [...order.data].reverse() : [];
    const preorderreversedOrders = preorder && preorder.data ? [...preorder.data].reverse() : [];
 
  return (
    
    <>
  
    <div className="sidebars" >
    <a  className="sidebars-on" href="/online-products">
         <h3 className="bar-content">Online Products</h3>
         </a>
     </div>
     
 <div class="main-bar">
 
   <Header  />
   <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a> <span></span> My Account
                </div>
            </div>
        </div>
        <div class="page-content pt-150 pb-150">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 m-auto">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="author-image mb-30 col-lg-5 col-sm-6 col-12">
                                    <a href="#"><img src={`https://api.arivomkadai.com/customer_data/${Profile}`} alt="" class="avatar"/></a>
                                    <div class="author-infor">
                                        <h5 class="mb-5">{userName}</h5>
                                        <p class="mb-0 text-muted font-xs">
                                            <span class="mr-10">{Email}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="dashboard-menu">
                                    <ul class="nav flex-column" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="dashboard-tab" data-bs-toggle="tab" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false"><i class="fi-rs-settings-sliders mr-10"></i>Dashboard</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="orders-tab" data-bs-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="false"><i class="fi-rs-shopping-bag mr-10"></i>Orders</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="preorders-tab" data-bs-toggle="tab" href="#preorders" role="tab" aria-controls="preorders" aria-selected="false"><i class="fi-rs-apps mr-10"></i>PreOrders</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="track-orders-tab" data-bs-toggle="tab" href="#track-orders" role="tab" aria-controls="track-orders" aria-selected="false"><i class="fi fi-rs-heart mr-10"></i>Wishlist</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="address-tab" data-bs-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="true"><i class="fi-rs-marker mr-10"></i>My Address</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="account-detail-tab" data-bs-toggle="tab" href="#account-detail" role="tab" aria-controls="account-detail" aria-selected="true"><i class="fi-rs-user mr-10"></i>Account details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class='nav-link' onClick={handleLogout}><i class="fi-rs-sign-out mr-10"></i>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="tab-content account dashboard-content ">
                                    <div class="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="mb-0">Hello {userName}!</h3>
                                            </div>
                                            <div class="card-body">
                                                <p>
                                                    From your account dashboard. you can easily check &amp; view your <a href="#">recent orders</a>,<br />
                                                    manage your <a href="#">shipping and billing addresses</a> and <a href="#">edit your password and account details.</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="mb-0">Your orders</h3>
                                            </div>
                                            <div class="row product-grid">

                                            {reversedOrders.map(order=> (
                                                <div class="col-lg-1-4 col-md-4 col-12 col-sm-6" key={order.id}>
                                                    <div class="product-cart-wrap mb-30">
                                                        <div class="product-content-wrap">
                                                            <div class="product-category">
                                                                <a >{order.order_id}</a>
                                                            </div>
                                                            <h2><a >{order.date}</a></h2>
                                                            <div>
                                                                {order.payment_status === 'paid'?( <span class="font-small" style={{color:"darkgreen", fontSize:'X-large'}}>{order.payment_status} <a ></a></span>):( <span class="font-small" style={{color:"red", fontSize:'X-large'}}>{order.payment_status} <a ></a></span>)}
                                                               
                                                            </div>
                                                            <div class="product-card-bottom">
                                                                <div class="product-price">
                                                                    <span>₹{order.grand_total}</span>
                                                                        </div>
                                                                <div class="add-cart">
                                                                    <a class='add' href={`/track?id=${order.order_id}`}>Track </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                     ))}
                                               {/*end product card*/}
                                            </div>
                                        </div>
                                    </div>


                                    <div class="tab-pane fade" id="preorders" role="tabpanel" aria-labelledby="preorders-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="mb-0">Your PreOrders</h3>
                                            </div>
                                            <div class="row product-grid">

                                            {preorderreversedOrders.map(order=> (
                                                <div class="col-lg-1-4 col-md-4 col-12 col-sm-6" key={order.id}>
                                                    <div class="product-cart-wrap mb-30">
                                                        <div class="product-content-wrap">
                                                            <div class="product-category">
                                                                <a >{order.order_id}</a>
                                                            </div>
                                                            <h2><a >{order.date}</a></h2>
                                                            <div>
                                                                {order.payment_status === 'paid'?( <span class="font-small" style={{color:"darkgreen", fontSize:'X-large'}}>{order.payment_status} <a ></a></span>):( <span class="font-small" style={{color:"red", fontSize:'X-large'}}>{order.payment_status} <a ></a></span>)}
                                                               
                                                            </div>
                                                            <div class="product-card-bottom">
                                                                <div class="product-price">
                                                                    <span>₹{order.grand_total}</span>
                                                                        </div>
                                                                <div class="add-cart">
                                                                    <a class='add' href={`/track?id=${order.order_id}`}>Track </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                     ))}
                                               {/*end product card*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="track-orders" role="tabpanel" aria-labelledby="track-orders-tab">
                                    {Wishlistdata && Wishlistdata.count === 0 ? (
        <div class="col-lg-8 mb-40">
     
        <h6 class="text-body"> Please add items to your wishlist.</h6>
        <div class="d-flex justify-content-between mt-5">
        <div class="cart-action d-flex justify-content-between" >
            <a href="/offline" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
        </div>
              </div>
    </div>
    
):(
    <div class="table-responsive shopping-summery">
        <table class="table table-wishlist">
            <thead>
                <tr class="main-heading">
                    <th class="custome-checkbox start pl-30">
                       
                    </th>
                    <th scope="col" colspan="2">Product</th>
                    <th scope="col">Price</th>
                    <th scope="col">Action</th>
                    <th scope="col" class="end">Remove</th>
                </tr>
            </thead>
            <tbody>
            {Wishlistdata && Wishlistdata.data && Wishlistdata.data.map(product=> (
                <tr class="pt-30" key={product.id}>
                    <td class="custome-checkbox pl-30">
                            </td>
                    <td class="image product-thumbnail pt-40"><img src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product}/></td>
                    <td class="product-des product-name">
                        <h6><a class='product-name mb-10' href={`/offlineproduct?id=${product.id}`}>{product.product}</a></h6>
                        
                    </td>
                    <td class="price" data-title="Price">
                        <h3 class="text-brand">₹{product.price}</h3>
                    </td>
                   
                    <td class="text-right" data-title="Cart">
                        <button class="btn btn-sm" onClick={() => addToCart(product.id)} >Add to cart</button>
                    </td>
                    <td class="action text-center" data-title="Remove">
                        <a class="text-body" onClick={() => removeProducts(product.id)}><i class="fi-rs-trash"></i></a>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
    )}
                                    </div>
                                    <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
                                  
                                   
                    <div class="row" style={{margin:'5px'}}>
                        <h4 class="mb-30">Select Address</h4>
                        { addresses && addresses.data && addresses.data.map(address => (
                     <div class="border p-10 cart-totals ml-30 mb-50 col-lg-5" key={address.id}>
                     <div class="custom-checkbox pr-5" style={{ display: 'flex', float: 'left' }}>
           
            <label class="form-check-label" for={address.id}>{address.label}</label> 
           
        </div>
        <a  class="text-body mt-5" style={{ display: 'flex', float: 'right' }}  onClick={() => removeAddress(address.id)}><i class="fi-rs-trash"></i></a>
         
                
                                         <div class='address-detail' >
                        <p class='product-short-desc'><small>{address.name}</small></p>
                        <p class='product-short-desc'><small>{address.building} {address.street}</small></p>
                        <small class='content-color font-default'>Email : <span class='title-color font-default fw-500'>{address.email}</span></small><br></br>
                        <small class='content-color font-default'>Mobile : <span class='title-color font-default fw-500'>{address.phonenumber}</span></small><br></br>
                        <small class='content-color font-default mt-1'>City : <span class='title-color font-default fw-500'>{address.city}</span></small><br></br>
                        <small class='content-color font-default mt-1'>State : <span class='title-color font-default fw-500'>{address.state === 'Other States' ? address.otherState : address.state}</span></small><br></br>
                        <small class='content-color font-default mt-1'>Pincode : <span class='title-color font-default fw-500'>{address.pincode}</span></small>
                      </div>                 
                    </div> 
                    ))}
                </div>

                {addresses && addresses.count <= 3 ? (
                                         
                                         <div class="row">
                                         <div class="col-lg-12 mb-40">
                                      
                                         <h6 class="text-body"> Add Address</h6>
                                        
                                               <form onSubmit={handleAddSubmit}>
                                                             <div class="ship_detail mt-15">
                                                                     <div class="row">
                                                                         <div class="form-group col-lg-4">
                                                                             <input type="text" required name="name" placeholder="First Name *" value={address.name} onChange={handleAddChange}/>
                                                                           
                                                                         </div>
                                                                         <div class="form-group col-lg-4">
                                                                             <input type="text" required name="email" placeholder="Email *" value={address.email} onChange={handleAddChange}/>
                                                                         </div>
                                                                        <div class="form-group col-lg-4">
                                                                            <input type="number" required name="number" placeholder="WhatsApp Number *" value={address.number} onChange={handleAddChange}/>
                                                                        </div>
                                                                    
                                                                    
                                                                   
                                        <div class="form-group col-lg-12">
                                            <input type="text" name="building" required placeholder="Enter Flat/Houseno/Building/company/Apartment/Area/streat/sector/village *" value={address.building} onChange={handleAddChange}/>
                                        </div>
                                        <div class="form-group col-lg-12">
                                            <input type="text" name="street" required placeholder="Enter Area/street/Sector/village *" value={address.street} onChange={handleAddChange}/>
                                        </div>
                                       
                                    <div class="form-group col-lg-4">
                                            <input required type="text" name="city" placeholder="Enter Town/city *" value={address.city} onChange={handleAddChange}/>
                                        </div>
                                        <div class="form-group col-lg-4">
                                        <select required 
                                                            className="select2-selection--single mb-2"
                                                            type="text"
                                                            name="state"
                                                            id="state"
                                                            value={address.state}
                                                            onChange={handleAddChange}
                                                        >
                                                         <option value="" disabled selected hidden>Select State</option>
														 <option value="Tamil Nadu">Tamil Nadu</option>
														 <option value="Kerala">Kerala</option>
														 <option value="Andhra Pradesh">Andhra Pradesh</option>
														 <option value="Karnataka">Karnataka</option>
														 <option value="Other States">Other States</option>
														
														 </select>
                                        </div>

                                        {address.state === 'Other States' && 
                                          <div class="form-group col-lg-4">
                                            <input required type="text" name="others" placeholder="Enter State*" value={address.others} onChange={handleAddChange}/>
                                          </div>
                                        }

                                        <div class="form-group col-lg-4">
                                            <input required type="text" name="pincode" placeholder="Enter Pincode/ Zip *" value={address.pincode} onChange={handleAddChange}/>
                                        </div>
                                        <div  class={`form-group  ${address.state === 'Other States' ? 'col-lg-8' : 'col-lg-12'}`}>
                                            <input type="text" name="landmark" required placeholder="Enter Landmark *" value={address.landmark} onChange={handleAddChange}/>
                                        </div>
                                                                     <div class="form-group col-lg-12" align="center">
                                                                     <button class="btn btn-md">Save Address</button>
                                                                    </div>
                                                                    </div> 
                                                                
                                                             </div>
                                                         </form>
                                     </div>
                                     </div>
    
                                        ):( <></>)}
                  
                                    </div>
                                    <div class="tab-pane fade" id="account-detail" role="tabpanel" aria-labelledby="account-detail-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5>Account Details</h5>
                                            </div>
                                            <div class="card-body">
                                                <form onSubmit={handleprofileSubmit}>
                                                    <div class="row">
                                                        <div class="form-group col-md-6">
                                                            <label>Full  Name <span class="required">*</span></label>
                                                            <input required class="form-control" name="name" type="text" value={profilename} onChange={handleprofileName}/>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                        <label>Email Address <span class="required">*</span></label>
                                                            <input required class="form-control" name="email" type="email" value={profileemail} onChange={handleprofileEmail}/>
                                                      
                                                            </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Phone Number <span class="required">*</span></label>
                                                            <input required class="form-control" name="number" type="number" value={profilenumber} onChange={handleprofileNumber}/>
                                                        </div>
                                                       
                                                        <div class="form-group col-md-6">
                                                            <label>Gender<span class="required">*</span></label>
                                                            <select required 
                                                           class="form-control" style={{height: "64px"}} name="gender" value={profilegender} onChange={handleprofileGender}
                                                          >
                                                          <option value="">Select Gender</option>
                                                           <option value="male">Male</option>
                                                           <option value="female">Female</option>
                                                         
                                                           </select>
                                                            
                                                            
                                                             </div>

                                                             <div class="form-group col-md-6">
                                                            <label>Enter Flat/Houseno/Building/company/Apartment/Area/streat<span class="required">*</span></label>
                                                            <input required class="form-control" name="building" type="text" value={profilebuilding} onChange={handlebuilding}/>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Enter Area/street/Sector/village <span class="required">*</span></label>
                                                            <input required class="form-control" name="street" type="text" value={profilestreet} onChange={handlestreet}/>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label>Enter Town/city <span class="required">*</span></label>
                                                            <input required class="form-control" name="city" type="text" value={profilecity} onChange={handlecity}/>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label>Enter state <span class="required">*</span></label>
                                                            <input required class="form-control" name="state" type="text" value={profilestate} onChange={handlestate}/>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label>Enter Pincode <span class="required">*</span></label>
                                                            <input required class="form-control" name="pincode" type="number" value={profilepincode} onChange={handlepincode}/>
                                                        </div>
                                                        
                                                        <div class="form-group col-md-12">
                                                            <label>Profile Image <span class="required">*</span></label>
                                                            <input class="form-control" id="photo" name="images" accept="image/png, image/jpeg, image/jpg, image/pdf, image/webp" type="file" onChange={handleFileChange}/>
                                                             </div>
                                                        <div class="col-md-12">
                                                            <button type="submit" class="btn btn-fill-out submit font-weight-bold">Save Change</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
 
     <Footeroffline />
 
     </div>
     </>
  )
}
export default Account;