import React, { useEffect, useRef } from 'react';
import Header from './header';
import Videos from '../videos/intro.mp4';
import Typed from 'typed.js';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");
function App() {
    const typedRef = useRef(null);
    if(!ReactSession.get("arkuserId")){
        ReactSession.set("arkuserId","0");
      }
    useEffect(() => {
      const typed = new Typed(typedRef.current, {
        strings: ['Selling', 'Genuine', 'Trending', 'Useful'],
        loop: true,
        typeSpeed: 100,
        backSpeed: 20,
        backDelay: 2500,
      });
  
      return () => {
        typed.destroy(); // Clean up Typed instance
      };
    }, []);


    
    return (

<>
   
	<Header  />
	

    <main class="main">
        <section class="home-slider position-relative">
                
                    <div class="hero-slider-2 style-4 dot-style-1 dot-style-1-position-1">
                    <div class="background-video">
                           <video src={Videos} muted autoPlay loop id="bgVideo" ></video>
                         </div>
						   
                            <div class="slider-content">



                            <div id="hero_title" class="mb-40">
                                <h1 class="ah-headline title text-white clip is-full-width ">
                                    <span> We've Most&nbsp;</span> 
                                    <span className="Multitext" ref={typedRef}></span>
									&nbsp;Products!
                                </h1>
                            </div>

                                <p class=" text-white mb-65">We Make Your Shopping Simple.</p>
                              
                                <a href="/online-products" class="btn mb-20 mr-20">Online Products</a>
                                <a href="/offline" class="btn mb-20" type="submit">Arivom Products</a>
                                
                           
                  
                    
                </div>
            </div>
        </section>
        <div class="social-icons">
            <a href="https://www.facebook.com/arivomkadaioffi"><img src="assets/imgs/theme/icons/icon-facebook-white.svg" alt="" /></a>
            <a href="https://twitter.com/arivomkadaioffi"><img src="assets/imgs/theme/icons/icon-twitter-white.svg" alt="" /></a>
            <a href="https://www.instagram.com/arivomkadaiofficial/"><img src="assets/imgs/theme/icons/icon-instagram-white.svg" alt="" /></a>
            <a href="https://youtube.com/@arivomkadai?si=lLD9fDxRApWUXTGc"><img src="assets/imgs/theme/icons/icon-youtube-white.svg" alt="" /></a>
    </div>
        </main>
        
    </>
    );
}

export default App;