import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Payment() {
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const navigate = useNavigate();
    const userId = ReactSession.get("arkuserId");
  const otpStatus = ReactSession.get("otp");
    const [error, setError]=useState({});


    if(userId > 0 && otpStatus == 'no'){
        navigate('/otp');
     }



     const fetchData = async () => {
      
        // console.log("user:",formData);
       try {
         const formData = {id:orderId};
         const response = await axios.post(`https://api.arivomkadai.com/processsucess/`, formData);
  
         setProcess(response.data);
         //console.log(response.data);
       } catch (error) {
         
         console.error("Error fetching data:", error);
       }
     };
     useEffect(() => {
     fetchData();
     
     
     }, []); 
 
 
 
     const fetchpreorderData = async () => {
       
         // console.log("user:",formData);
        try {
          const formData = {id:orderId};
          const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
   
          setpreorderProcess(response.data);
          //console.log(response.data);
        } catch (error) {
          
          console.error("Error fetching data:", error);
        }
      };
      useEffect(() => {
         fetchpreorderData();
      
      
      }, []); 


      const payregular = async(event) => {
   
        const formData = {
         paymentstaus:'paid',
         userId: userId,
         orderId:orderId,
        };
    
        //console.log(formData);
        try {
            const response = await axios.post(`https://api.arivomkadai.com/paymentstatus/`, formData);
  
          
           // console.log(response.data);
           // alert(`Added successfully`);
            navigate(`/paymentstatus?id=${orderId}`);
            //navigate(`/processsucess?id=${orderId}`);
            sessionStorage.removeItem('cartItems');
        } catch (error) {
            console.error('There was a problem with login:', error);
            setError('Not Inserted');
        }
 
      
      }
      const cancelregular = async(event) => {
    
         const formData = {
         paymentstaus:'notpaid',
         userId: userId,
         orderId:orderId,
         };
     
         //console.log(formData);
         try {
             const response = await axios.post(`https://api.arivomkadai.com/paymentstatus/`, formData);
            // console.log(response.data);
           
            
            // alert(`Added successfully`);
                navigate(`/paymentstatus?id=${orderId}`);
             //navigate(`/processsucess?id=${orderId}`);
             sessionStorage.removeItem('cartItems');
         } catch (error) {
             console.error('There was a problem with login:', error);
             setError('Not Inserted');
         }
  
       
       }


    const pay = async(event) => {
   
       const formData = {
        paymentstaus:'paid',
        userId: userId,
        orderId:orderId,
       };
   
       //console.log(formData);
       try {
           const response = await axios.post(`https://api.arivomkadai.com/preorderpaymentstatus/`, formData);
 
         
           //console.log(response.data);
          // alert(`Added successfully`);
          navigate(`/paymentstatus?id=${orderId}`);
           //navigate(`/processsucess?id=${orderId}`);
           sessionStorage.removeItem('cartItems');
       } catch (error) {
           console.error('There was a problem with login:', error);
           setError('Not Inserted');
       }

     
     }
     const cancel = async(event) => {
   
        const formData = {
        paymentstaus:'notpaid',
        userId: userId,
        orderId:orderId,
        };
    
        //console.log(formData);
        try {
            const response = await axios.post(`https://api.arivomkadai.com/preorderpaymentstatus/`, formData);
           // console.log(response.data);
          
           
           // alert(`Added successfully`);
               navigate(`/paymentstatus?id=${orderId}`);
            //navigate(`/processsucess?id=${orderId}`);
            sessionStorage.removeItem('cartItems');
        } catch (error) {
            console.error('There was a problem with login:', error);
            setError('Not Inserted');
        }
 
      
      }

  return (
    <main class="main">
     <div class="container">
    <div class="row align-items-centre">


    {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder'?(<>
        <div class="col-lg-8 mb-40">
        <h5 class="heading-2 mb-10">payment</h5>
        <div class="d-flex justify-content-between mt-5">
        <div class="cart-action d-flex justify-content-between" >
            <a  class="btn " onClick={pay}><i class="fi-rs-arrow-left mr-10"></i>make Payment</a>
            <a class="btn ml-5 " onClick={cancel}><i class="fi-rs-arrow-left mr-10"></i>Cancel</a>
        </div>
              </div>
    </div>
    </>):(<>
        <div class="col-lg-8 mb-40">
        <h5 class="heading-2 mb-10">payment</h5>
        <div class="d-flex justify-content-between mt-5">
        <div class="cart-action d-flex justify-content-between" >
            <a  class="btn " onClick={payregular}><i class="fi-rs-arrow-left mr-10"></i>make Payment</a>
            <a class="btn ml-5 " onClick={cancelregular}><i class="fi-rs-arrow-left mr-10"></i>Cancel</a>
        </div>
              </div>
    </div>
    </>)}
    
</div>
</div>
</main>
  )
}
export default Payment;
