import React from 'react';
import Header from './headerpage';
import Headers from './headerpages';
import Footer from './footer';
import Footers from './footeroffline';
import Slider from './slider';


function About () {
    const queryParameters = new URLSearchParams(window.location.search)
    const about = queryParameters.get("id") 
    return [
        <>
        
        {about === '1' ? (
  <>
    <div class="sidebars">
      <a href="/offline">
        <h3 class="bar-content">Arivom Products</h3>
      </a>
    </div>
  
  </>
) : (
  <>
    <div class="sidebars">
   
      <a href="/online-products" >
        <h3 class="bar-content">Online Products</h3>
      </a>
    </div>
  </>
)}
      <div class="main-bar">
      {about === '1' ? (<Header  />):(<Headers  />)}
      
        <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a> <span></span> About us
                </div>
            </div>
        </div>
        <div class="page-content pt-50">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 col-lg-12 m-auto">
                        <section class="row align-items-center mb-50">
                            <div class="logo1" style={{width:'50%',marginLeft:'25%', marginRight: '25%;', zIndex:'1'}}>
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" />
                            </div>
                            <div class='about-border' >
                                <div class="pl-25">
                                        <h2 class="mb-30" style={{textAlign:'center'}}>Welcome to Arivomkadai</h2>
                                        <p class="mb-25">Arivomkadai mission is to make your shopping simple. Our goal is to save you time and eliminate the stress of shopping, whether you're looking for everyday gear, kitchen products, Automobile accessories or gifts for loved ones.</p>
                                    <div class="col-lg-12">
                                        <p class="mb-30">We strive to be the most trusted product recommendation online platform. I strongly believe “There is no shortcut to success or to earn money" . I know you guys earning your money with lot of hard work and effort, I wish that hard earned money should spend only on quality products and service. Hence I am helping you guys to choose the best products with my little bit of knowledge and experience. </p>
                                        <p class="mb-30">
                                        I give buying links to all the products I come across or I personally experience all of which are my affiliate links. Using them for your purchase won't increase the price, but it will help you to choose the best product with excellent customer ratings or reviews. Sometimes, it helps you to buy the product with good discounts and make you to save your hard earned money. Meaning it give us some perks to run my page without getting any other paid/sponsored advertisements. Hence I never mislead my followers to buy some product for sake of money. Even if we do paid promotion, 100% we will inform the same to our followers.
                                        </p>
                                        <p class="mb-30">
                                        We independently review some products we recommend. We also suggest best products we came across though our friends or family circle.  When you buy through our links, we may earn a small commission.  And of course, the decisions we make regarding the products we feature on our site are always driven by personal experience and product testing standards, not by affiliate deals or advertising relationships or sponsors. 
                                        </p>
                                
                                        <p class="mb-20" style={{borderTop: 'solid 1px'}}>
                                        If any company or corporates, wish to promote your product or service - We will share both positives and negatives to our followers. If you are okay with that, please approach us at  <strong><a href='arivomkadaiofficial@gmail.com'>arivomkadaiofficial@gmail.com</a></strong>
                                        </p>
                                    </div>
                                </div>
                            </div> 
                        </section>     
                        {/* <section class="text-center mb-50">
                            <h2 class="title style-3 mb-40">What We Provide?</h2>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 mb-24">
                                    <div class="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-1.svg" alt="" />
                                        <h4>Best Prices & Offers</h4>
                                        <p>Enjoy the best prices and exclusive offers on authentic Tamil products at Arivomkadai, where quality meets affordability.</p>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-24">
                                    <div class="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-2.svg" alt="" />
                                        <h4>Wide Assortment</h4>
                                        <p>Explore a wide assortment of authentic Tamil products at Arivomkadai, catering to all your cultural and contemporary needs</p>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-24">
                                    <div class="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-3.svg" alt="" />
                                        <h4>Free Delivery</h4>
                                        <p>Experience the convenience of free delivery on all your favorite Tamil products at Arivomkadai.</p>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-24">
                                    <div class="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-4.svg" alt="" />
                                        <h4>Easy Returns</h4>
                                        <p>Enjoy hassle-free shopping with easy returns on all purchases at Arivomkadai.</p>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-24">
                                    <div class="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-5.svg" alt="" />
                                        <h4>100% Satisfaction</h4>
                                        <p>Shop with confidence and enjoy 100% satisfaction guaranteed at Arivomkadai.</p>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-24">
                                    <div class="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-6.svg" alt="" />
                                        <h4>Great Daily Deal</h4>
                                        <p>Take advantage of our great daily deals and enjoy fantastic savings every day at Arivomkadai.</p>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        {/* <section class="row align-items-center mb-50"> */}
                           {/* <div class="row mb-50 align-items-center">
                                <div class="col-lg-7 pr-30">
                                    <img src="assets/imgs/page/about-5.png" alt="" class="mb-md-3 mb-lg-0 mb-sm-4" />
                                </div>
                                <div class="col-lg-5">
                                    <h4 class="mb-20 text-muted">Our performance</h4>
                                    <h1 class="heading-1 mb-40">Exceptional Service, Every Time</h1>
                                    <p class="mb-30">At Arivomkadai, we take pride in our commitment to exceptional performance, ensuring that every customer interaction is met with efficiency, professionalism, and satisfaction. Our dedicated team works tirelessly to exceed expectations, providing seamless transactions, prompt assistance, and a memorable shopping experience</p>
                                    <p>With a focus on continuous improvement, we strive to uphold the highest standards of service excellence, making Arivomkadai your trusted destination for quality and reliability.</p>
                                </div>
                            </div>*/}
                            {/* <div class="row">
                                <div class="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                                    <h3 class="mb-30">Food</h3>
                                    <p>Savor the rich flavors of Tamil Nadu with our authentic selection of traditional spices, snacks, and sweets, crafted with care to delight your taste buds. From aromatic masalas to mouthwatering delicacies, experience the essence of Tamil cuisine at Arivomkadai.</p>
                                </div>
                                <div class="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                                    <h3 class="mb-30">Travel</h3>
                                    <p>Embark on a journey through the cultural heartlands of Tamil Nadu with Arivomkadai's curated travel essentials, ensuring you explore every corner with comfort and style. From guidebooks to travel accessories, we've got you covered for an enriching and unforgettable Tamil travel experience.</p>
                                </div>
                                <div class="col-lg-4">
                                    <h3 class="mb-30">Products</h3>
                                    <p>Discover an extensive range of authentic Tamil products at Arivomkadai, meticulously curated to celebrate the essence of Tamil culture in every item. From literature to handicrafts, apparel to home decor, find the perfect blend of tradition and modernity with our diverse selection.</p>
                                </div>
                            </div>
                        </section> */}
                    </div>
                </div>
            </div>
            
           {/* <div class="container">
                <div class="row">
                    <div class="col-xl-10 col-lg-12 m-auto">
                        <section class="mb-50">
                            <h2 class="title style-3 mb-40 text-center">Our Team</h2>
                            <div class="row">
                                <div class="col-lg-4 mb-lg-0 mb-md-5 mb-sm-5">
                                    <h6 class="mb-5 text-brand">Our Team</h6>
                                    <h1 class="mb-30">Meet Our Expert Team</h1>
                                    <p class="mb-30">Proin ullamcorper pretium orci. Donec necscele risque leo. Nam massa dolor imperdiet neccon sequata congue idsem. Maecenas malesuada faucibus finibus.</p>
                                    <p class="mb-30">Proin ullamcorper pretium orci. Donec necscele risque leo. Nam massa dolor imperdiet neccon sequata congue idsem. Maecenas malesuada faucibus finibus.</p>
                                    <a href="#" class="btn">View All Members</a>
                                </div>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="team-card">
                                                <img src="assets/imgs/page/about-6.png" alt="" />
                                                <div class="content text-center">
                                                    <h4 class="mb-5">H. Merinda</h4>
                                                    <span>CEO & Co-Founder</span>
                                                    <div class="social-network mt-20">
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-facebook-brand.svg" alt="" /></a>
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-twitter-brand.svg" alt="" /></a>
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-instagram-brand.svg" alt="" /></a>
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-youtube-brand.svg" alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="team-card">
                                                <img src="assets/imgs/page/about-8.png" alt="" />
                                                <div class="content text-center">
                                                    <h4 class="mb-5">Dilan Specter</h4>
                                                    <span>Head Engineer</span>
                                                    <div class="social-network mt-20">
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-facebook-brand.svg" alt="" /></a>
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-twitter-brand.svg" alt="" /></a>
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-instagram-brand.svg" alt="" /></a>
                                                        <a href="#"><img src="assets/imgs/theme/icons/icon-youtube-brand.svg" alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                        </div>*/}
        </div>
    </main>
       
       
        {about === '1' ? (<Slider/>):(<div></div>)}
        {about === '1' ? (<Footer />):(<Footers  />)}
    
        </div>
        </>
    ]
}
export default About;