import React, { useEffect, useState } from 'react';

import axios from 'axios';
import '../App.css';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';

ReactSession.setStoreType("localStorage");

 function Paymentstatus() {
    const navigate = useNavigate();
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
   // console.log(orderId);

   const userId = ReactSession.get("arkuserId");
   const otpStatus = ReactSession.get("otp");
   if(userId > 0 && otpStatus == 'no'){
    navigate('/otp');
 }
    const fetchData = async () => {
      
       // console.log("user:",formData);
      try {
        const formData = {id:orderId};
        const response = await axios.post(`https://api.arivomkadai.com/processsucess/`, formData);
 
        setProcess(response.data);
      //  console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
    fetchData();
    
    
    }, []); 



    const fetchpreorderData = async () => {
      
        // console.log("user:",formData);
       try {
         const formData = {id:orderId};
         const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
  
         setpreorderProcess(response.data);
      // console.log(response.data);
       } catch (error) {
         
         console.error("Error fetching data:", error);
       }
     };
     useEffect(() => {
        fetchpreorderData();
     
     
     }, []); 
  return (
    <>
  


 

  <main class="main">
       
        <div class="page-header mt-25 mb-10">
            <div class="container" style={{maxHeight:'100vh', marginTop: '25vh', marginBottom: 'auto'}}>
            


                          
                            
                <div class="archive-headers" >
                {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder'?(<>
                    <div class="row align-items-center">
                   
                   {preorderprocess && preorderprocess.data && preorderprocess.data.payment_status === "paid" ?
                   (<>
                    <div class="loop-grid loop-list pr-30 mb-50">
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                                <div class="post-thumb  pl-50 pt-5" >
                                <h3 class="post-title mb-20">
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" style={{width:'50%',marginLeft:'auto', marginRight: 'auto', zIndex:'1'}}/><br></br>
                                        <a href='blog-post-right.html'>Thank you for your purchase</a>
                                    </h3>
                                    <p class="post-exerpt mb-40">Your order will be Processed Within 24 hours during working days. We will notify you by Whatsapp and <br></br> E-mail once your order has been shipped.</p>
                                    <div class="container col-md-12 mb-4 mb-md-0">
        <div class="contact-info">
            <div><b>Name:</b></div>
            <div class="data">{preorderprocess && preorderprocess.data && preorderprocess.data.name}</div>
            
            <div><b>Address:</b></div>
            <div class="data">{preorderprocess && preorderprocess.data && preorderprocess.data.buiulding} {preorderprocess && preorderprocess.data && preorderprocess.data.street} {preorderprocess && preorderprocess.data && preorderprocess.data.city} {preorderprocess && preorderprocess.data && preorderprocess.data.state}</div>
            
            <div><b>Pincode:</b></div>
            <div class="data">{preorderprocess && preorderprocess.data && preorderprocess.data.pincode}</div>
            
            <div><b>Phone:</b></div>
            <div class="data">{preorderprocess && preorderprocess.data && preorderprocess.data.phonenumber}</div>
            
            {/* <div><b>Email:</b></div>
            <div class="data">{process && process.data && process.data.email}</div> */}
        </div>
    </div>
    </div>
                                <div class="entry-content-2">
                                
                                <div class="border p-40 cart-totals ml-30 mb-50" >
                        <div class="d-flex align-items-end justify-content-between mb-30">
                            <h4>Order Summary</h4>
                           
                        </div>
                        <div class="col-xl-12  d-none d-xl-block">
                            <ul class="tags-list">
                                <li class="hover-up">
                                    <p>Date<br></br>{preorderprocess && preorderprocess.data && preorderprocess.data.date}</p>
                                </li>
                                <li class="hover-up">
                                    <p>orderID<br></br>{preorderprocess && preorderprocess.data && preorderprocess.data.id}</p>
                                </li>
                                <li class="hover-up">
                                    <p>Payment Method<br></br>Online</p>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="divider-2 mb-30"></div>
                        <div class="table-responsive order_table checkout scrollable-table-container">
                        <div class="scrollable-table-content">
                            <table class="table no-border">
                                <tbody>
                                {preorderprocess && preorderprocess.data && preorderprocess.data.orderdetails && preorderprocess.data.orderdetails.map(product => (
                                    <tr key={product.id}>
                                        <td class="image product-thumbnail"><img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product}/></td>
                                        <td>
                                            <h6 class="w-160 mb-5"><a class='text-heading' href={`preorderproduct?id=${product.id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a>
                                            <p class="font-xxs ">{product.code}<br></br>{product.varient}</p>
                                            </h6>
                                            
                                        </td>
                                        <td>
                                        <p class="font-sm ">₹{product.price}</p>
                                        </td>
                                        <td>
                                        <p class="font-sn">x{product.quantity}</p>
                                            
                                        </td>
                                        <td>
                                            <h4 class="text-brand">₹{product.subtotal}</h4>
                                        </td>
                                    </tr>
                                  ))}
                                    <tr>
        <td colSpan="2" class="cart_total_label">
            <h6 class="text-muted ml-20">Subtotal</h6>
        </td>
        <td colSpan="2" class="cart_total_amount">
            <h4 class="text-brand text-end">₹ {Number(preorderprocess && preorderprocess.data && preorderprocess.data.total).toFixed(2)}</h4>
        </td>
    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                   
                    <a href={`/track?id=${orderId}`} class="btn mt-20 w-10">Track<i class="fi-rs-sign-out ml-1"></i></a>
                                </div>
                               
                            </article>
                           
                            </div>   
                   </>)
                   :(<> 
                   
                   <div class="loop-grid loop-list pr-30 mb-50">
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                                <div class="post-thumb" style={{textAlignLast: "center"}}> 
                                <h3 class="post-title mb-20" style={{textAlignLast: "center"}}>
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" style={{width:'50%',marginLeft:'auto', marginRight: 'auto', zIndex:'1'}}/><br></br>
                                        <a href='blog-post-right.html'>Unable to process your request.!</a>
                                    </h3>
                                    <p class="post-exerpt mb-40">Unable to verify your payment details. If you deduct any amount it will refund within 7 days. Thank you!</p>
                                    <a href='/offline' class="btn mt-20 w-10 mb-10">Home<i class="fi-rs-sign-out ml-15"></i></a>
    </div>
   
                               
                            </article>
                           
                            </div>
                     </>)}
                
                 
                 
                 
               
                 
                 </div>
                </>)
                :(<>
                 <div class="row align-items-center">
                   
                   {process && process.data && process.data.order_status === "paid" ?
                   (<>
                     <div class="loop-grid loop-list pr-30 mb-50">
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                                <div class="post-thumb  pl-50 pt-5" >
                                <h3 class="post-title mb-20">
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" style={{width:'50%',marginLeft:'auto', marginRight: 'auto', zIndex:'1'}}/><br></br>
                                        <a href='blog-post-right.html'>Thank you for your purchase</a>
                                    </h3>
                                    <p class="post-exerpt mb-40">Your order will be Processed Within 24 hours during working days. We will notify you by Whatsapp and <br></br> E-mail once your order has been shipped.</p>
                                    <div class="container col-md-12 mb-4 mb-md-0">
        <div class="contact-info">
            <div><b>Name:</b></div>
            <div class="data">{process && process.data && process.data.name}</div>
            
            <div><b>Address:</b></div>
            <div class="data">{process && process.data && process.data.buiulding} {process && process.data && process.data.street} {process && process.data && process.data.city} {process && process.data && process.data.state}</div>
            
            <div><b>Pincode:</b></div>
            <div class="data">{process && process.data && process.data.pincode}</div>
            
            <div><b>Phone:</b></div>
            <div class="data">{process && process.data && process.data.phonenumber}</div>
            
            {/* <div><b>Email:</b></div>
            <div class="data">{process && process.data && process.data.email}</div> */}
        </div>
    </div>
    </div>
                                <div class="entry-content-2">
                                
                                <div class="border p-40 cart-totals ml-30 mb-50" >
                        <div class="d-flex align-items-end justify-content-between mb-30">
                            <h4>Order Summary</h4>
                           
                        </div>
                        <div class="col-xl-12  d-none d-xl-block">
                            <ul class="tags-list">
                                <li class="hover-up">
                                    <p>Date<br></br>{process && process.data && process.data.date}</p>
                                </li>
                                <li class="hover-up">
                                    <p>orderID<br></br>{process && process.data && process.data.id}</p>
                                </li>
                                <li class="hover-up">
                                    <p>Payment Method<br></br>Online</p>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="divider-2 mb-30"></div>
                        <div class="table-responsive order_table checkout scrollable-table-container">
                        <div class="scrollable-table-content">
                            <table class="table no-border" width="100%">
                                <tbody>
                                {process && process.data && process.data.orderdetails && process.data.orderdetails.map(product => (
                                    <tr key={product.id}>
                                        <td width="20%" class="image product-thumbnail"><img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product}/></td>
                                        <td>
                                            <h6 class="w-160 mb-5"><a class='text-heading' href={`offlineproduct?id=${product.id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a>
                                            <p class="font-xxs ">{product.code}<br></br>{product.varient}</p>
                                            </h6>
                                            
                                        </td>
                                        <td>
                                        <p class="font-sm ">₹{product.price}</p>
                                        </td>
                                        <td>
                                        <p class="font-sn">x{product.quantity}</p>
                                            
                                        </td>
                                        <td>
                                            <h4 class="text-brand">₹{product.subtotal}</h4>
                                        </td>
                                    </tr>
                                  ))}
                                   <tr>
                    <td colSpan="2" class="cart_total_label">
                        <h6 class="text-muted ml-20">Subtotal</h6>
                    </td>
                    <td colSpan="2" class="cart_total_amount">
                        <h4 class="text-brand text-end">₹ {Number(process && process.data && process.data.total).toFixed(2)}</h4>
                    </td>
                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                   
                    <a href={`/track?id=${orderId}`} class="btn mt-20 w-10">Track<i class="fi-rs-sign-out ml-1"></i></a>
                                </div>
                               
                            </article>
                           
                            </div>   
                   </>)
                   :(<> 
                   
                   <div class="loop-grid loop-list pr-30 mb-50">
                            <article class="wow fadeIn animated hover-up mb-30 animated">
                                <div class="post-thumb" style={{textAlignLast: "center"}}> 
                                <h3 class="post-title mb-20" style={{textAlignLast: "center"}}>
                                <img src="assets/imgs/page/about-1.png" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" style={{width:'50%',marginLeft:'auto', marginRight: 'auto', zIndex:'1'}}/><br></br>
                                        <a href='blog-post-right.html'>Unable to process your request.!</a>
                                    </h3>
                                    <p class="post-exerpt mb-40">Unable to verify your payment details. If you deduct any amount it will refund within 7 days. Thank you!</p>
                                    <a href='/offline' class="btn mt-20 w-10 mb-10">Home<i class="fi-rs-sign-out ml-15"></i></a>
    </div>
   
                               
                            </article>
                           
                            </div>
                     </>)}
                
                 
                 
                 
               
                 
                 </div>
                </>)}
               
                   
                </div>
            </div>
        </div>

       





        
    </main>

  

  </>
  )
}
export default Paymentstatus;
