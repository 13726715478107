import React, { useEffect, useState } from 'react';
import Header from './headerpages';
import Footeroffline from './footeroffline';
import axios from 'axios';

 function Addcart() {
    
      const [cartItems, setCartItems] = useState([]);
      const [preorder, setPreorder] = useState([]);
      const [total, setTotal] = useState(0);
      const [gtotal, setGTotal]= useState(0);
      const [offer, setOffer] = useState(0);
      const [preordertotal, setpreorderTotal] = useState(0);
      const [preordergtotal, setpreorderGTotal]= useState(0);
      const [preorderoffer, setpreorderOffer] = useState(0);
      const [preordershipping, setpreorderShipping] = useState(0);
      const [shipping, setShipping] = useState(0);
      const [coupon, setCoupon]= useState("");
      
      const [selectedcartItem, setSelectedcartItem] = useState({ productId: '', quantity: 0, stock:0, code:'', stockpreorder:''});
      
      const handleCoupon= (event) => {
        setCoupon(event.target.value); // Changed setInput to setLink
    };
    const handlepreorderCoupon= (event) => {
      setCoupon(event.target.value); // Changed setInput to setLink
  };
	

    
    const handleCouponcode = async (event) => {
        event.preventDefault(); // Prevent default form submission
       // console.log("Coupon Code:", coupon);
        await fetchCouponData(); // Call function to fetch coupon data
      };
    
      const fetchCouponData = async () => {
        let $off = 0;
        let $dis = 0;
        let $upto = 0;
        let $offers = 0;
        
        try {
          const response = await axios.get(`https://api.arivomkadai.com/couponoffline/`);
          const coupons = response.data && response.data.data;
          const foundCoupon = coupons && coupons.filter(item => item.code === coupon);
          
          if (foundCoupon.length > 0) {
            const currentDate = new Date();
            const offercoupon = foundCoupon[0];
            const endDate = new Date(offercoupon.e_date);
            const startDate = new Date(offercoupon.s_date);
            
           // console.log(offercoupon); // Logging the start date of the coupon
          
            if (currentDate < startDate) {
              alert("Coupon not available");
            } 
            if (currentDate > endDate) {
              alert("Coupon expired");
            } else {
              alert("Available");
              $off = offercoupon.offer; // Discount percentage
              $dis = total*($off / 100); // Calculate discount based on total and discount percentage
             // console.log($dis);
              $upto = offercoupon.upto; // Maximum discount value
              
              if ($dis > $upto) {
                $offers = $upto;
              } else {
                $offers = $dis;
              }
              const coupon={
                id:offercoupon.id,
                code:offercoupon.code,
                discount:offercoupon.offer,
                offer: $offers.toFixed(2),
              }
              
              // Assuming setOffer is a function to update state in React
              setOffer($offers); // Update offer state in your component
              sessionStorage.setItem('offer', $offers);
              sessionStorage.setItem('coupon', JSON.stringify(coupon));
             // console.log(coupon);
            }
          } else {
            //console.log("Coupon not found");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error appropriately, e.g., show an error message or log it
        }
        
        // Now you can use $off, $dis, $upto, and $offer outside of the if block if needed
        //console.log("$off:", $off);
       // console.log("$dis:", $dis);
       // console.log("$upto:", $upto);
       // console.log("$offer:", $offer);
      };

       
    const handlepreorderCouponcode = async (event) => {
      event.preventDefault(); // Prevent default form submission
     // console.log("Coupon Code:", coupon);
      await fetchpreorderCouponData(); // Call function to fetch coupon data
    };
  
    const fetchpreorderCouponData = async () => {
      let $off = 0;
      let $dis = 0;
      let $upto = 0;
      let $offers = 0;
     
      
      try {
        const response = await axios.get(`https://api.arivomkadai.com/couponpreorder/`);
        const coupons = response.data && response.data.data;
        const foundCoupon = coupons && coupons.filter(item => item.code === coupon);


       
        
        if (foundCoupon.length > 0) {
          const currentDate = new Date();
          const offercoupon = foundCoupon[0];
          const endDate = new Date(offercoupon.e_date);
          const startDate = new Date(offercoupon.s_date);
          
        // console.log(offercoupon);
        
          if (currentDate < startDate) {
            alert("Coupon not available");
          } 
          if (currentDate > endDate) {
            alert("Coupon expired");
          } else {
            alert("Available");
            $off = offercoupon.offer; // Discount percentage
            $dis = preordertotal*($off / 100); // Calculate discount based on total and discount percentage
          // console.log($dis);
           //console.log($off);
            $upto = offercoupon.upto; // Maximum discount value
            
            if ($dis > $upto) {
              $offers = $upto;
            } else {
              $offers = $dis;
            }
            const preordercoupon={
              id:offercoupon.id,
              code:offercoupon.code,
              discount:offercoupon.offer,
              offer:$offers,
            }
            // Assuming setOffer is a function to update state in React
            setpreorderOffer($offers); // Update offer state in your component
            sessionStorage.setItem('preorderoffer', $offers);
            sessionStorage.setItem('preordcoupon', JSON.stringify(preordercoupon));
           //console.log(preordercoupon);
          }
        } else {
          //console.log("Coupon not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately, e.g., show an error message or log it
      }
      
      // Now you can use $off, $dis, $upto, and $offer outside of the if block if needed
      //console.log("$off:", $off);
     // console.log("$dis:", $dis);
     // console.log("$upto:", $upto);
     // console.log("$offer:", preorderoffer);
      //console.log("$offer:", $offers);
    };








      const openModals1 = (stock) => {
        const modal = document.getElementById('copyModals1');
        const count = document.getElementById('stockCount1');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
            count.textContent = stock;
           
        }
      };
      
      const Continue = () => {
       // console.log(`Updating cart for productId: 1`);
        // // Destructure productId and quantity from selectedItem
         const { productId, quantity, stock,code, stockpreorder} = selectedcartItem;
        // // Call updateCart with productId and quantity
       // console.log(`Updating cart for productId: ${productId}, quantity: ${quantity}, stock: ${stock}`);
        const carts = JSON.parse(sessionStorage.getItem('cartItems')) || [];
            const productIndex = carts.findIndex(item => item.id === productId);
        carts[productIndex].preorder = 'yes';
        sessionStorage.setItem('cartItems', JSON.stringify(carts)); // Save updated cart items to session storage
        setCartItems(carts);
        updateCart(productId, quantity, stock, preorder, code, stockpreorder);
        closeModals1();
      };
          
        
        
        
              const closeModals1 = (stock) => {
                const modal = document.getElementById('copyModals1');
               
                if (modal) {
                    modal.classList.remove('show');
                    modal.style.display = 'none';
                    document.body.classList.remove('modal-open');
                 
                }
              };
      
             
             
      
      
      
          const handleQuantityChangeadd = async (itemId, event) => {
              const value = event.target.value;
              const quantity = parseInt(value);
           //console.log(quantity);
              try {
                  const response = await axios.get(`https://api.arivomkadai.com/offlineproduct/`);
                  const product = response && response.data && response.data.data.filter(items => items.p_id === itemId);
                  const stock = product[0].stock;
                  const stockpreorder = product[0].preorder;
                  if (!isNaN(quantity)) {
                      // Update quantity state with the parsed integer value
                      updateCart(itemId, quantity, stock, stockpreorder); // Assuming updateCart function updates the cart state with itemId and new quantity
                  }
              } catch (error) {
                  console.error("Error updating quantity:", error);
              }
          };

    

          const handleIncrement = async (productId, quantity) => {
            try {
                const response = await axios.get(`https://api.arivomkadai.com/offlineproduct/`);
                const product = response && response.data && response.data.data.filter(items => items.p_id === productId);
                const stock = product[0].stock;
                const code = product[0].product_code;
                const stockpreorder = product[0].preorder;
                const productname = product[0].product;
                updateCart(productId, quantity + 1, stock, code, stockpreorder); 
               // console.log(`Updated cart for ${productname}: ${quantity + 1}`);
            } catch (error) {
                console.error("Error fetching stock data:", error);
            }
        };
    
    const handleDecrement = async (productId, quantity) => {
        try {
            const response = await axios.get(`https://api.arivomkadai.com/offlineproduct/`);
            const product = response && response.data && response.data.data.filter(items => items.p_id === productId);
            const stock = product[0].stock;
            const stockpreorder = product[0].preorder;
            const code = product[0].product_code;
            if (quantity > 1) {
              //console.log(quantity);
              updateCart(productId, quantity - 1, stock, code, stockpreorder);
              //console.log(`Updated cart for ${productname}: ${quantity - 1}`);
          } else {
             // alert("Quantity cannot be less than 1");
          } 
        } catch (error) {
            console.error("Error fetching stock data:", error);
        }
    };

    
    const openmodalpreorder = (stock) => {
      const modal = document.getElementById('copyModalspreorder');
      if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
          document.body.classList.add('modal-open');
          
      }
      
    
      
    };
    const closeModalspreorder = () => {
      const modal = document.getElementById('copyModalspreorder');
      if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        
        
        const inputField = document.querySelector('.qty-val');
        if (inputField) {
          inputField.value = ''; 
        }
      }
    };
    
    
    
      
    const updateCart = (productId, quantity, stock, code, stockpreorder) => {
      const carts = JSON.parse(sessionStorage.getItem('cartItems')) || [];
      const productIndex = carts.findIndex(item => item.id === productId);
  
      if (productIndex === -1) {
          // Product not found in cart, handle accordingly (optional)
          return;
      }
  
      if (stock >= quantity) {
          // Sufficient stock scenario
          carts[productIndex].quantity = quantity;
          carts[productIndex].preorder = 'No';
      } else {
       
          if (carts[productIndex].preorder === 'yes') {
            
            // Already preordered
           carts[productIndex].quantity = quantity;
        } else {
          if(stockpreorder === '0'){
            
              const newItem = {
                code: code,
                productId: productId,
                quantity: quantity,
                stock: stock,
                stockpreorder:stockpreorder
            };
            setSelectedcartItem(newItem);
             openModals1(stock);
        }else{
         
              
          openmodalpreorder(stock);
    }
            
             // Open modals or handle UI interaction
        }

       
          // Insufficient stock scenario
          
      }
  
      // Update sessionStorage and state with modified carts
      sessionStorage.setItem('cartItems', JSON.stringify(carts));
      setCartItems(carts);
  };
  

      
    useEffect(() => {
      let sum = 0;
      
      if (cartItems && cartItems.length > 0) {
        cartItems.forEach(item => {
          sum += item.price * item.quantity; // Calculate total sum based on price and quantity
        });
        setTotal(sum);
       
    
//         let amount = ''; 
//         // Check if shipping.data exists and is an array before iterating
// if (shipping && shipping.data && Array.isArray(shipping.data)) {
//   let amount = 0; // Initialize amount variable
  
//   shipping.data.forEach(item => {
//     if (sum > item.value && sum < item.value1) {
//       amount = item.above;
//     } else if (sum < item.value && sum < item.value1) {
//       amount = item.below;
//     } else if (sum > item.value && sum > item.value1) {
//       amount = item.above1;
//     }
    
//     // Assuming `setDeliveryAmount` sets the delivery amount somewhere
//     setDeliveryAmount(amount);
//   });
  
//   // Storing delivery amount in session storage
//   sessionStorage.setItem('deliveryAmount', amount.toString());
// }

        
        // Set delivery amount
        
        //console.log('amount:' +amount);
        //console.log('total' +total);
        //console.log('offer'+offer);
      //console.log('delivery' +delivery);


   
        const g_total = Number(total) -  Number(offer) ;
      
      // Set g_total and offer
      setGTotal(g_total);
      setOffer(offer);
      } else {
        // If cart is empty, reset totals
        setTotal(0); 
        setGTotal(0); 
        setOffer(0);
      }
    
      // Calculate g_total after setting offer
      
      
      
       // Assuming delivery is a state variable
    
    }, [cartItems, offer, shipping, gtotal]);
    
    
      
      
      useEffect(() => {
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
        updateCart();
      }, []);



      const removeProducts = (index) => {
        const updatedCart = [...cartItems];
        updatedCart.splice(index, 1);
        setCartItems(updatedCart);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCart));
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
      };



      useEffect(() => {

        const fetchofflinedelivery = async () => {
          try {
            const response = await axios.get('https://api.arivomkadai.com/deliveryoffline/');
            setShipping(response.data);
           // console.log(response.data);
          } catch (error) {
            
            console.error("Error fetching data:", error);
          }
        };
      
        fetchofflinedelivery();
      
      
      }, []);










        
    //preorder
     
    
    const handlepreorderIncrement = (productId, quantity) => {
      if (quantity < 10) {
          updatedpreCart(productId, quantity + 1); 
      }
      else{
          updatedpreCart(productId, 10); 
        }
    };
    
    const handlepreorderDecrement = (productId, quantity) => {
      if (quantity > 1) {
          updatedpreCart(productId, quantity - 1); 
      }
    };

    const productquantity=(productId, event)=>{
      const value=  parseInt(event.target.value);
      

      
      if(value >10){
          updatedpreCart(productId, 10); 
         
      }else{
          updatedpreCart(productId, value);
      }
  
   }

    

      
    



      const updatedpreCart = (productId, quantity) => {
        //console.log("Product:", productId);
        const preorder = JSON.parse(sessionStorage.getItem('preorder')) || [];
        //console.log("cart:", carts);
        const productIndex = preorder.findIndex(item => item.id === productId);
        //console.log("productIndex:", productIndex);
        if (productIndex === -1) {
            //console.log('Product not found in cart.');
            return;
        }

        if(quantity>0){
          preorder[productIndex].quantity = quantity;
         }else{
           preorder[productIndex].quantity = '';
         }
        //preorder[productIndex].quantity = quantity;
        sessionStorage.setItem('preorder', JSON.stringify(preorder)); // Change 'cart' to 'cartItems'
        setPreorder(preorder);
        //console.log('Updated cart:', carts);
       // console.log(cartItems);
    };
    
    useEffect(() => {
       
        updatedpreCart();
        setShipping(0);
       
    }, []); 




    useEffect(() => {
        if (preorder && preorder.length > 0) {
          let sum = 0;
          preorder.forEach(item => {
            sum += item.price * item.quantity; // Assuming each item has price and quantity properties
          });
          setpreorderTotal(sum);
    
          // Calculate $g_total
         
           
    
          const g_total = Number(preordertotal) - Number(preorderoffer);
    
          
          setpreorderGTotal(g_total);
          setpreorderOffer(preorderoffer);
        //console.log(g_total);
       // console.log(preorderoffer);
        } else {
          setpreorderTotal(0); 
          setpreorderGTotal(0); 
          setpreorderOffer(0);
        }
      }, [preorder, preorderoffer, preordertotal]); 


      useEffect(() => {
        const storedPreorderItems = JSON.parse(sessionStorage.getItem('preorder')) || [];
        setPreorder(storedPreorderItems);
        updatedpreCart();
       //console.log(storedPreorderItems);
        
      }, []);

      
      const removepreProducts = (index) => {
        const updatedpreCart = [...preorder];
        updatedpreCart.splice(index, 1);
        setPreorder(updatedpreCart);
        sessionStorage.setItem('preorder', JSON.stringify(updatedpreCart));
        localStorage.setItem('preorder', JSON.stringify(updatedpreCart));
      };
      

    


  return (
    <>
    <div class="sidebars">
    <a  className="sidebars-on" href="/online-products">
      <h3 class="bar-content">Online Products </h3>
    </a>
</div>
<div class="main-bar">

  <Header  />

  <main class="main">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span> Cart
                </div>
            </div>
        </div>


        <div class="modal fade custom-modal" id="copyModalspreorder" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalspreorder}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">Insufficent Stock</p>
                        <button className="button mr-10 mt-10" onClick={closeModalspreorder}>Cancel</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade custom-modal" id="copyModals1" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModals1}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      <h4 class="mb-30">Out of Stock</h4>
                        {/* <p class="mb-30">Currently, this product is out of stock. Our executive will contact you within 24 hours after placing your order to discuss the delivery time.</p> */}
                        <p class="mb-30">Available stock is <span id="stockCount1"></span>  If you wish, Kindly preorder the balance quantity after completing this order.</p>
                    
                    </div>
                    <button className="button mr-10 mt-10" onClick={Continue}>Continue</button>
                    <button className="button mr-10 mt-10" onClick={closeModals1}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       
       {cartItems.length > 0 ?(<>  <div class="container mb-20 mt-20">
                
        {cartItems.length === 0 ? (
    <div class="row">
    <div class="col-lg-12 mt-40" align="center">
            <h5 class="heading-2 mb-10">Your Cart</h5>
            <h6 class="text-body"> Please add items to your cart.</h6>
                <a href="/offline" class="btn mt-30 mb-80"><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
        </div>
</div>
) : (
    <div class="row">
    <div class="col-lg-9 ">
        <div class=" table-responsive shopping-summery">
            <table class="table table-wishlist" width="100%">
                <thead>
                    <tr class="main-heading ">
                        <th scope="col" width="50%" class="pl-20" >Product</th>
                        <th scope="col">Price</th>
                        <th scope="col"style={{textAlign:'center'}}>qty</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col" class="end">Action</th>
                    </tr>
                </thead>
                <tbody>
                {cartItems.map((item, index) => (
                    <tr class="pt-20 mt-50" key={index}>
                      
                        <td class="image product-thumbnail pl-20">
                        {item.preorder === 'yes' ? (<div style={{ position: 'relative', display: 'inline-block' }}>
      <span style={{
        position: 'absolute',
        top: '0',
        right: '-30px',
        background: 'yellow',
        padding: '2px 10px',
        color: 'black',
        borderRadius: '15px',
        boxShadow: '3px 2px 2px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
        fontSize: '9px',
        fontWeight: 'bold'
      }}>
        Pre Order
      </span>
      <img alt={item.product} src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{marginTop: '10px', maxWidth: "70px" }} />
    </div>):(
        <img alt={item.product} src={`https://api.arivomkadai.com/product_data/${item.image}`} style={{ maxWidth: "70px" }} />
    )}
                       
                         <br></br>   <small class="text-center text-headings product-name"><a href={`offlineproduct?id=${item.id}`}> {item.size !== '' ? (<>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product+' ( '+item.size+' '+item.weight+' )'}</>) : (<>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</>)}</a></small>
                          
                        </td>
                        <td class="price md-none" >
                            <h6 class="text-body">₹{item.price} </h6>
                        </td>
                        <td class="text-center detail-info">
                        <div class="detail-extralink ">
                        <button className="btn1" onClick={() => handleDecrement(item.id, item.quantity)}>-</button>
                                           
                                           <div className="detail-qty border radius">
                                             
                                          
                                           <input
                                             type="text"
                                             name="quantity"
                                             className="qty-val"
                                             placeholder={item.quantity}
                                             min="1"
                                             onChange={(e) => handleQuantityChangeadd(item.id, e)} // Pass item id and event object
      
                                            
                                           />
                                           </div>
                                           <button className="btn1"  onClick={() => handleIncrement(item.id, item.quantity)}>+ </button>
                        </div>
                        </td>
                        <td class="price" data-title="">
                            <h6 class="text-brand">₹{item.price*item.quantity} </h6>
                        </td>
                        <td className="action text-center mobile-view" data-title="">
                            <button className=" text-white" style={{padding: '10px 15px', background: 'red', border: 'none', borderRadius: '10px'}} onClick={() => removeProducts(index)}>
                                <i className="fi-rs-trash"></i>
                            </button>
                        </td>
                          </tr>
                        ))}
                    
                </tbody>
            </table>
        </div>
        
    </div>
    
    <div class="col-lg-3">
        <div class="border p-md-2 cart-totals">
        <div class="col-lg-12">
                
                    <h4 class="mb-10">Apply Coupon</h4>
                  
                    <div class="form-group" style={{display: 'flex', alignItems: 'center'}}>
                        <input required="required"  placeholder="Enter coupon" name="coupon" type="text" value={coupon}  onChange={handleCoupon}/>
                         <button onClick={handleCouponcode} class="btn mt-5"style={{marginLeft: '10px'}}><i class="fi-rs-label mr-10"></i>Apply</button>
                        </div>
                       
                  
             
            </div>
            <div class="table-responsive">
                <table class="table no-border">
                    <tbody>
                        <tr>
                            <td class="cart_total_label">
                                <h6 class="text-muted">Subtotal</h6>
                            </td>
                            <td class="cart_total_amount">
                                <h4 class="text-brand text-end">₹{total.toFixed(2)}</h4>
                            </td>
                        </tr>
                        <tr>
                            <td  colspan="2">
                                <div class="divider-2 mt-10 mb-10"></div>
                            </td>
                        </tr>
                        {/* <tr>
                            <td class="cart_total_label">
                                <h6 class="text-muted">Shipping</h6>
                            </td>
                         
                            <td class="cart_total_amount">
                            <h5 class="text-heading text-end">₹{Number(delivery).toFixed(2)}</h5></td> </tr> */}
                             <tr>
                           
                            <td class="cart_total_label">
                                <h6 class="text-muted">Coupon</h6>
                            </td>
                            <td class="cart_total_amount">
                                <h5 class="text-heading text-danger text-end">₹{Number(offer).toFixed(2)}</h5></td> </tr> <tr>
                            <td  colspan="2">
                                <div class="divider-2 mt-10 mb-10"></div>
                            </td>
                        </tr>
                        <tr>
                            <td class="cart_total_label">
                                <h6 class="text-muted">Total</h6>
                            </td>
                            <td class="cart_total_amount">
                                <h4 class="text-brand text-end">₹{Number(gtotal).toFixed(2)}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <a  href="/checkout" class="btn mb-20 w-100">Proceed To CheckOut</a>
        </div>
    </div>
       <div align="center">
            <a href="/offline" class="btn mt-20 mb-50"><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
        </div>
</div>
)}

           
            

        </div></>):
        (<>
        <div class="container mb-20 mt-20">
                
            {preorder.length === 0 ? (
        <div class="row">
        <div class="col-lg-12 mt-40" align="center">
            <h5 class="heading-2 mb-10">Your Cart</h5>
            <h6 class="text-body"> Please add items to your cart.</h6>
                <a href="/offline" class="btn mt-30 mb-80"><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
        </div>
    </div>
    ) : (
        <div class="row">
        <div class="col-lg-8">
            <div class="table-responsive shopping-summery">
                <table class="table table-wishlist">
                    <thead>
                        <tr class="main-heading">
                            <th class="custome-checkbox start pl-30">
                               
                            </th>
                            <th scope="col" colspan="2">Product</th>
                            <th scope="col">Price</th>
                            <th scope="col">qty</th>
                            <th scope="col">Subtotal</th>
                            <th scope="col" class="end">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {preorder.map((item, index) => (
                        <tr class="pt-30" key={index}>
                            <td class="custome-checkbox pl-30">
                            
                            </td>
                            <td class="image product-thumbnail pt-40"><img src={`https://api.arivomkadai.com/product_data/${item.image}`} width="50" alt={item.product} /></td>
                            <td class="product-des product-name pr-50">
                                <h6 class="mb-5"><a class='product-name mb-10 text-headings' href={`offlineproduct?id=${item.id}`}>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</a></h6>
                              
                            </td>
                            <td class="price" data-title="Price">
                                <h4 class="text-body">₹{item.price} </h4>
                            </td>
                            <td class="text-center detail-info" data-title="Stock">
                            <div class="detail-extralink ">
                            <button className="btn1" onClick={() => handlepreorderDecrement(item.id, item.quantity)}>-</button>
                                               
                                               <div className="detail-qty border radius">
                                                 
                                              
                                               <input
                                                 type="number"
                                                 name="quantity"
                                                 className="qty-val"
                                                 value={item.quantity}
                                                 min="1"
                                                 max="10"
                                                 onChange={(e) => productquantity(item.id, e)}
                                               />
                                               </div>
                                               <button className="btn1"  onClick={() => handlepreorderIncrement(item.id, item.quantity)}>+ </button>
                            </div>
                            </td>
                            <td class="price" data-title="Price">
                                <h4 class="text-brand">₹{item.price*item.quantity} </h4>
                            </td>
                            <td className="action text-center" data-title="Remove">
    <button  className="text-white" style={{padding: '10px 15px', background: 'red', border: 'none', borderRadius: '10px'}} onClick={() => removepreProducts(index)}>
    <i className="fi-rs-trash"></i>
    </button>
    </td>
                              </tr>
                            ))}
                        
                    </tbody>
                </table>
            </div>
            <div class="divider-2 mb-30"></div>
            <div class="cart-action d-flex justify-content-between">
                <a href="/offline" class="btn "><i class="fi-rs-arrow-left mr-10"></i>Continue Shopping</a>
               
            </div>
           
        </div>
        <div class="col-lg-4">
            <div class="border p-md-4 cart-totals ml-30">
            <div class="col-lg-12">
                
                <h4 class="mb-10">Apply Coupon</h4>
              
                <div class="form-group" style={{display: 'flex', alignItems: 'center'}}>
                    <input required="required"  placeholder="Enter coupon" name="coupon" type="text" value={coupon}  onChange={handlepreorderCoupon}/>
                     <button onClick={handlepreorderCouponcode} class="btn mt-5"style={{marginLeft: '10px'}}><i class="fi-rs-label mr-10"></i>Apply</button>
                    </div>
                   
              
         
        </div>
                <div class="table-responsive">
                    <table class="table no-border">
                        <tbody>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Subtotal</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹{preordertotal.toFixed(2)}</h4>
                                </td>
                            </tr>
                            <tr>
                                <td  colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            {/* <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Shipping</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-end">₹{preordershipping.toFixed(2)}</h5></td> </tr>  */}
                                    <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Coupon</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-danger text-end">₹{Number(preorderoffer).toFixed(2)}</h5></td> </tr> <tr>
                                <td  colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Total</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹{Number(preordergtotal).toFixed(2)}</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <a  href="/checkout?id=preorder" class="btn mb-20 w-100">Proceed To CheckOut<i class="fi-rs-sign-out ml-15"></i></a>
            </div>
        </div>
    </div>
    )}
    
               
                
    
            </div>
        </>)}


    </main>

  <Footeroffline />

  </div>
  </>
  )
}
export default Addcart;
