import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
ReactSession.setStoreType("localStorage");

 function Login() {
    const [input, setInput] = useState({});
    const [error, setError]=useState({});
    const [formsubmit, setsubmit]=useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    if(!ReactSession.get("arkuserId")){
      ReactSession.set("arkuserId","0");
    }
    else{
      ReactSession.set("arkuserId", ReactSession.get("arkuserId"));
    }

    const handleChange=(event)=>{
        const name = event.target.name;
        const value =event.target.value;
        setInput(values => ({...values, [name]: value}))
    }

    const handleSubmit =async(event)=>{
        event.preventDefault();
        setsubmit(true);
  
      try {
        const response = await axios.post('https://api.arivomkadai.com/logincustomer/', input);
        
        if(response.data.status === 'success' ){
            //console.log(response.data);
          
          ReactSession.set("arkuserId", response.data.data[0].user_id);
        ReactSession.set("userName", response.data.data[0].name);
        ReactSession.set("email", response.data.data[0].email);
        ReactSession.set("phonenumber", response.data.data[0].phonenumber);
        ReactSession.set("gender", response.data.data[0].gender);
        ReactSession.set("profile", response.data.data[0].profile);
        ReactSession.set("building", response.data.data[0].buiulding);
        ReactSession.set("street", response.data.data[0].street);
        ReactSession.set("city", response.data.data[0].city);
        ReactSession.set("state", response.data.data[0].state);
        ReactSession.set("pincode", response.data.data[0].pincode);
      
         alert(`Welcome ${response.data.data[0].name}`);
          
         navigate('/offline');
        }else{
          //console.log(response.data.error.message);
          ReactSession.set("arkuserId", "0");
          const errors ='Username or Password is incorrect'
          setMessage(errors);
        }
  
        setError(validate(response));
        
        } catch (error) {
          console.error('Login failed!', error);
          ReactSession.set("arkuserId", "0");
        }
  
    }

    const validate=(response)=>{
        const errors={};
          if(response.data.error){
            errors.name = response.data.error.message;
          }
          return errors;
      }
    
      useEffect(()=>{
        //console.log(error);
        if(Object.keys(error).length ===0 & formsubmit){
          
          //console.log(input);
        }
       
      });
      
     




  return (
  <>
   <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a> <span></span> Login
                </div>
            </div>
        </div>
        <div class="page-content pt-150 pb-150">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-10 col-md-12 m-auto">
                        <div class="row">
                            <div class="col-lg-6 pr-30 d-none d-lg-block">
                                <img class="border-radius-15" src="assets/imgs/page/login-1.png" alt="" />
                            </div>
                            <div class="col-lg-6 col-md-8">
                                <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Login</h1>
                                            <p class="mb-30">Don't have an account? <a href='/register'>Create here</a></p>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                        <div class="form-group">
                                                <input type="email" required name="email" placeholder="Enter Email" value={input.email} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="password" name="password" placeholder="Enter Password"  value={input.password} onChange={handleChange}/>
                                            </div>
                                            {message && message.length > 0 && (
                                              <p className="text-danger">{message}</p>
                                            )}
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Log in</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </>
  )
}
export default Login;