import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';

const Slider = () => {
    
    const [images, setImages] = useState([]);

    const fetchData = async () => {
        try {
          const response = await axios.get('https://api.arivomkadai.com/testimonial/');
          if (response.data.status === 'success') {
            const imgArray = response.data.data.map(item => item.image);
            setImages(imgArray);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, []);



  
  const totalImages = images.length;
  const style = `
    @keyframes scrollImage {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-100% * ${totalImages})); /* Adjust the multiplier based on the number of images */
      }
    }

    .image-container {
      display: flex;
      overflow: hidden;
      width: 100%;
    }

    .image-container img {
      display: inline-block;
      height: 300px;
      margin-right: 20px;
      animation: scrollImage ${totalImages*2}s linear infinite; 
      border-radius: 10px;
      border: rgba(0, 0, 0, 0.2) solid 2px;
    }
  `;

  const extendedImages = [...images, ...images, ...images];
   
  return (
    <div>
      <style>{style}</style>
      <div className="image-container">
        {extendedImages.map((image, index) => (
          <img key={index} src={`https://api.arivomkadai.com/testimonial_data/${image}`} alt={`Slide ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default Slider;
