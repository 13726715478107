import React, { useEffect, useState } from 'react';
import Header from './headerpages';
import Footer from './footer';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useNavigate} from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HomeComponent from './invoice';
ReactSession.setStoreType("localStorage");

 function Track() {
    const navigate = useNavigate();
    const [process, setProcess] = useState([]);
    const [preorderprocess, setpreorderProcess] = useState([]);
    const [ordertracking, setorderTracking] = useState([]);
    const [preordertracking, setpreorderTracking] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    sessionStorage.setItem('id', orderId);

   // console.log(orderId);

   const userId = ReactSession.get("arkuserId");
   const otpStatus = ReactSession.get("otp");
   if(userId > 0 && otpStatus == 'no'){
    navigate('/test');
 }
    const fetchData = async () => {
      
       // console.log("user:",formData);
      try {
        const formData = {id:orderId};
        const response = await axios.post(`https://api.arivomkadai.com/processsucess/`, formData);
 
        setProcess(response.data);
      // console.log(response.data);
      } catch (error) {
        
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
    fetchData();
    
    
    }, []); 
    

    const fetchDatatrack = async () => {
      
      // console.log("user:",formData);
     try {
       const formData = {id:orderId};
       const response = await axios.post(`https://api.arivomkadai.com/order_tracking/`, formData);

       setorderTracking(response.data);
       //console.log(response.data);
     } catch (error) {
       
       console.error("Error fetching data:", error);
     }
   };
   useEffect(() => {
    fetchDatatrack();
   
   
   }, []); 



    const fetchpreorderData = async () => {
      
        // console.log("user:",formData);
       try {
         const formData = {id:orderId};
         const response = await axios.post(`https://api.arivomkadai.com/proordersucess/`, formData);
  
         setpreorderProcess(response.data);
        //console.log(response.data);
       } catch (error) {
         
         console.error("Error fetching data:", error);
       }
     };
     useEffect(() => {
        fetchpreorderData();
     
     
     }, []); 

     const fetchpreorderDatatrack = async () => {
      
      // console.log("user:",formData);
     try {
       const formData = {id:orderId};
       const response = await axios.post(`https://api.arivomkadai.com/preorder_tracking/`, formData);

       setpreorderTracking(response.data);
      // console.log(response.data);
     } catch (error) {
       
       console.error("Error fetching data:", error);
     }
   };
   useEffect(() => {
    fetchpreorderDatatrack();
   
   
   }, []); 

     
  const handleDownloadPDF = async () => {
   // openModal();
    const input = document.getElementById('pdf-content');
    input.classList.remove('hide');
  
    const pdf = new jsPDF({
      orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
      unit: 'pt', // Set unit to millimeters
      format: 'a4', // Set format to A4
    });
    const inputHeight = input.clientHeight;
    const pageHeight = pdf.internal.pageSize.getHeight()+600;
    let currentPosition = 0;
    const scaleFactor = 4; 
    
    // Increase this value for higher resolution and better quality
  
    while (currentPosition < inputHeight) {
      const canvas = await html2canvas(input, {
        height: Math.min(pageHeight),
        
        y: currentPosition,
        scrollY: -currentPosition,
        scale: scaleFactor
      });

      

      
      const imgData = canvas.toDataURL('image/png', 4);
      pdf.addImage(imgData, 'PNG', 0, 0, 580, pdf.internal.pageSize.getHeight(), '', 'FAST');
      //currentPosition +=canvas.height;
      currentPosition += pageHeight;
      if (currentPosition < inputHeight) {
        pdf.addPage();
      }
     
    }
    pdf.save(orderId);
    
    input.classList.add('hide');
   // closeModal();
            sessionStorage.removeItem('id');
  };
    

  return (
    <>
    <div class="sidebars">
    <a  className="sidebars" href="/online-products">
      <h3 class="bar-content">Arivom Products </h3>
    </a>
</div>
<div class="main-bar">

  <Header  />

  <main class="main">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span> Sucess
                </div>
            </div>
        </div>
        <div class="page-header mt-25 mb-10">
            <div class="container">
            <div class="archive-headers">
                {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder' ? (
  <>
    <div class="row align-items-center">
      {preorderprocess && preorderprocess.data && preorderprocess.data.payment_status === "paid" ? (
        <>
          {preorderprocess.data.status === "Order Placed" && (
            <>
              <div class="col-xl-12" style={{ width:'20%', marginLeft:'35%', marginRight: '35%', zIndex:'1'}}>
                <img src="assets/svg/check.svg" alt="" class="border-radius-15 check mb-md-3 mb-lg-0 mb-sm-4" /> 
                <img src="assets/svg/order-success.svg" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4 mt-40" />
              </div>
              <div class="col-xl-12" style={{ textAlignLast: "center" }}>
                <h1 class="mb-5">Order Placed.</h1>
                <h5 class="font-light">{orderId}</h5>
              </div>
            </>
          )}
          {preorderprocess.data.status === "Placed" && (
            <>
              <div class="col-xl-12" style={{ width:'20%', marginLeft:'35%', marginRight: '35%', zIndex:'1'}}>
                <img src="assets/svg/check.svg" alt="" class="border-radius-15 check mb-md-3 mb-lg-0 mb-sm-4" /> 
                <img src="assets/svg/order-success.svg" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4 mt-40" />
              </div>
              <div class="col-xl-12" style={{ textAlignLast: "center" }}>
                <h1 class="mb-5" style={{color:"darkgreen"}}>Order Placed.</h1>
                <h5 class="font-light">{orderId}</h5>
              </div>
            </>
          )}
          {preorderprocess.data.status === "Shipped" && (
            <>
              <div class="col-xl-12" style={{ width:'20%', marginLeft:'35%', marginRight: '35%', zIndex:'1'}}>
                <img src="assets/svg/shipping.svg" alt="" />
              </div>
              <div class="col-xl-12" style={{ textAlignLast: "center" }}>
                <h1 class="mb-5" style={{color:"darkblue"}}>Order Shipping.</h1>
                <h5 class="font-light">{orderId}</h5>
              </div>
            </>
          )}
          {preorderprocess.data.status === "Delivered" && (
            <>
              <div class="col-xl-12" style={{ width:'20%', marginLeft:'35%', marginRight: '35%', zIndex:'1'}}>
                <img src="assets/svg/delivery.svg" alt="" />
              </div>
              <div class="col-xl-12" style={{ textAlignLast: "center" }}>
                <h1 class="mb-5" style={{color:'darkorange'}}>Order Delivered.</h1>
                <h5 class="font-light">{orderId}</h5>
              </div>
            </>
          )}
          {preorderprocess.data.status === "Cancelled" && (
            <>
              <div class="col-xl-12" style={{ width:'20%', marginLeft:'35%', marginRight: '35%', zIndex:'1'}}>
                <img src="assets/svg/cancelled.png" alt="" />
              </div>
              <div class="col-xl-12" style={{ textAlignLast: "center" }}>
                <h1 class="mb-5" style={{color:"red"}}>Order Cancelled.</h1>
                <h5 class="font-light">{orderId}</h5>
              </div>
            </>
          )}
        </>
      ) : (
        <> 
          <div class="col-xl-12" style={{ width:'20%', marginLeft:'35%', marginRight: '35%', zIndex:'1'}}>
            <img src="assets/svg/cancelled.png" alt="" />
          </div>
          <div class="col-xl-12" style={{ textAlignLast: "center" }}>
            <h1 class="mb-5" style={{color:"red"}}>Order Failed.</h1>
            <h5 class="font-light">{orderId}</h5>
          </div>
        </>
      )}
    </div>
  </>
)
                :(<>
  <div class="row align-items-center">
    {process && process.data && process.data.order_status === "paid" ? (
      <>
        {process.data.status === "Order Placed" && (
          <>
            <div class="col-xl-12" style={{ width:'20%',marginLeft:'35%', marginRight: '35%;', zIndex:'1'}}>
              <img src="assets/svg/check.svg" alt="" class="border-radius-15 check mb-md-3 mb-lg-0 mb-sm-4" /> 
              <img src="assets/svg/order-success.svg" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4 mt-40" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h1 class="mb-5" style={{color:"darkgreen"}}>Order Placed.</h1>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.data.status === "Confirm" && (
          <>
            <div class="col-xl-12" style={{ width:'20%',marginLeft:'35%', marginRight: '35%;', zIndex:'1'}}>
              <img src="assets/svg/check.svg" alt="" class="border-radius-15 check mb-md-3 mb-lg-0 mb-sm-4" /> 
              <img src="assets/svg/order-success.svg" alt="" class="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4 mt-40" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h1 class="mb-5" style={{color:"darkgreen"}}>Order Placed.</h1>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.data.status === "Dispatched" && (
          <>
            <div class="col-xl-12" style={{ width:'20%',marginLeft:'35%', marginRight: '35%;', zIndex:'1'}}>
              <img src="assets/svg/shipping.svg" alt="" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h1 class="mb-5" style={{color:"darkblue"}}>Order Shipping.</h1>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.data.status === "Delivered" && (
          <>
            <div class="col-xl-12" style={{ width:'20%',marginLeft:'35%', marginRight: '35%;', zIndex:'1'}}>
              <img src="assets/svg/delivery.svg" alt="" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h1 class="mb-5"  style={{color:'darkorange'}}>Order Delivered.</h1>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
        {process.data.status === "Cancelled" && (
          <>
            <div class="col-xl-12" style={{ width:'20%',marginLeft:'35%', marginRight: '35%;', zIndex:'1'}}>
              <img src="assets/svg/cancelled.png" alt="" />
            </div>
            <div class="col-xl-12" style={{textAlignLast: "center"}}>
              <h1 class="mb-5"  style={{color:"red"}}>Order Cancelled.</h1>
              <h5 class="font-light">{orderId}</h5>
            </div>
          </>
        )}
      </>
    ) : (
      <>
        <div class="col-xl-12" style={{ width:'20%',marginLeft:'35%', marginRight: '35%;', zIndex:'1'}}>
          <img src="assets/svg/cancelled.png" alt="" />
        </div>
        <div class="col-xl-12" style={{textAlignLast: "center"}}>
          <h1 class="mb-5" style={{color:"red"}}>Order Failed.</h1>
          <h5 class="font-light">{orderId}</h5>
        </div>
      </>
    )}
  </div>
</>)}
               
                   
                </div>
            </div>
        </div>

       





        <div class="container mb-80 mt-50">



             {preorderprocess && preorderprocess.data && preorderprocess.data.preorder === 'preorder'?(
                <div class="row">
       
                <div class="col-lg-8">
                    
                    <div class="table-responsive shopping-summery">
                        <table class="table table-wishlist">
                            <thead>
                                <tr class="main-heading">
                                    <th class="custome-checkbox start pl-30">
                                       
                                    </th>
                                    <th scope="col" colspan="2">Product</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">qty</th>
                                    <th scope="col">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                            {preorderprocess && preorderprocess.data && preorderprocess.data.orderdetails && preorderprocess.data.orderdetails.map(product => (
                    <tr className="pt-30"key={product.id}>
                        <td className="custome-checkbox pl-30">
                          
                        </td>
                        <td className="image product-thumbnail pt-40">
                            <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product}/>
                        </td>
                        <td className="product-des product-name pr-50">
                            <h6 className="mb-5"><a className='product-name mb-10 text-heading' >{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}
                            <p class="font-xxs">{product.code}<br></br>{product.varient}</p></a></h6>
                        </td>
                        <td className="price" data-title="Price">
                            <h4 className="text-body">₹{product.price}</h4>
                        </td>
                        <td className="text-center detail-info" data-title="Stock">
                            <h4 className="text-body">x{product.quantity}</h4>
                        </td>
                        <td className="price" data-title="Price">
                            <h4 className="text-brand">₹{product.subtotal}</h4>
                        </td>
                    </tr>
                ))}
            </tbody>
            
            
                        </table>
                    </div>
                    <div class="divider-2 mb-30"></div>
                   
                </div>
                <div class="col-lg-4">
                    <div class="border p-md-4 cart-totals ml-30">
                   
                        <div class="table-responsive">
                            <table class="table no-border">
                            <tbody>
                 
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Subtotal</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹ {Number(preorderprocess && preorderprocess.data && preorderprocess.data.total).toFixed(2)}</h4>
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Shipping</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-end">₹{Number(preorderprocess && preorderprocess.data && preorderprocess.data.shipping).toFixed(2)}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Coupon Discount</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h5 class="text-heading text-danger text-end">- ₹{Number(preorderprocess && preorderprocess.data && preorderprocess.data.offer).toFixed(2) || 0.00}</h5>
                                </td>
                            </tr>

                            {preorderprocess && preorderprocess.data && preorderprocess.data.gift > 0 ? (<> <tr>
                             <td class="cart_total_label">
                             <h6 class="text-muted">GiftBox</h6>
                             </td>
                             <td class="cart_total_amount">
                                 <h4 class="text-brand text-end">₹{Number(preorderprocess && preorderprocess.data && preorderprocess.data.gift).toFixed(2)}</h4>
                             </td>
                         </tr></>):(<></>)} 
                            <tr>
                                <td scope="col" colspan="2">
                                    <div class="divider-2 mt-10 mb-10"></div>
                                </td>
                            </tr>
                            <tr>
                                <td class="cart_total_label">
                                    <h6 class="text-muted">Total</h6>
                                </td>
                                <td class="cart_total_amount">
                                    <h4 class="text-brand text-end">₹{Number(preorderprocess && preorderprocess.data && preorderprocess.data.grandtotal).toFixed(2) || 0}.00</h4>
                                </td>
                            </tr>
                        
                
                </tbody>
                            </table>

                            <h5>Shipping Details</h5>
               
               <div class="tab-pane fade active show mt-15"  id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <div class="loop-grid loop-list  mb-50" >
                   <article class="wow fadeIn animated hover-up mb-30 animated">
                     <div class="entry-content-2 pl-50">
                     <span>Customer Name : <span>{preorderprocess && preorderprocess.data && preorderprocess.data.name}</span></span><br></br>
               
                       <span>Order Id : <span>{preorderprocess && preorderprocess.data && preorderprocess.data.id}</span></span><br></br>
               
                        <ul class='order-summery'>
                          <li>
                            <span>Placed on: {preorderprocess && preorderprocess.data && preorderprocess.data.date}</span>
                          </li>
                    
                         
                    
                        </ul>
                         <p class="post-exerpt">Address: {preorderprocess && preorderprocess.data && preorderprocess.data.building} {preorderprocess && preorderprocess.data && preorderprocess.data.area}</p>
                         <p class="post-exerpt">city: {preorderprocess && preorderprocess.data && preorderprocess.data.building} {preorderprocess && preorderprocess.data && preorderprocess.data.city}</p>
                         <p class="post-exerpt">state: {preorderprocess && preorderprocess.data && preorderprocess.data.building} {preorderprocess && preorderprocess.data && preorderprocess.data.state}</p>
                         <p class="post-exerpt">pincode: {preorderprocess && preorderprocess.data && preorderprocess.data.building} {preorderprocess && preorderprocess.data && preorderprocess.data.pincode}</p>
                         <p class="post-exerpt">Phone Number: {preorderprocess && preorderprocess.data && preorderprocess.data.building} {preorderprocess && preorderprocess.data && preorderprocess.data.phonenumber}</p>
             
                     </div>
                    </article>
                 </div>
                 <h5>Order Tracing</h5>

                 <div class="loop-grid loop-list  mb-50" >
                   <article class="wow fadeIn animated hover-up mb-30 animated">
                     <div class="entry-content-2 pl-50">
                       <span>Order Id : <span>{preordertracking && preordertracking.data && preordertracking.data.order_id}</span></span><br></br>
               
                        <ul class='order-summery'>
                          <li>
                          {preordertracking && preordertracking.data && preordertracking.data.status.map((statusObj, index) => (
                              
    <p key={index} className="post-excerpt">
      <span>Placed on: {statusObj.order_time} <br></br>
        Status: {statusObj.status}
        </span>
    </p>
   
))}
                          </li>
                    
                        </ul>
                      
                     </div>
                    </article>
                 </div>
                 
                 <HomeComponent id="pdf-content"/>
				 {preorderprocess && preorderprocess.data && preorderprocess.data.payment_status === "paid" ? (
                 <button
                onClick={handleDownloadPDF}
      className={`btn4 btn-primary`}
      style={{  marginLeft:'100px'}}
    >
     Download Invoice
				 </button>):''}
               </div>
                        </div>
                        </div>
                </div>
            </div>
            ):
            (
                <div class="row">
       
    <div class="col-lg-8">
        
        <div class="table-responsive shopping-summery">
            <table class="table table-wishlist">
                <thead>
                    <tr class="main-heading">
                        <th class="custome-checkbox start pl-30">
                           
                        </th>
                        <th scope="col" colspan="2">Product</th>
                        <th scope="col">Price</th>
                        <th scope="col">qty</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                {process && process.data && process.data.orderdetails && process.data.orderdetails.map(product => (
        <tr className="pt-30"key={product.id}>
            <td className="custome-checkbox pl-30">
              
            </td>
            <td className="image product-thumbnail pt-40">
                <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product}/>
            </td>
            <td className="product-des product-name pr-50">
                <h6 className="mb-5"><a className='product-name mb-10 text-heading' >{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}<br></br>
                <p class="font-xxs">{product.code}<br></br>{product.varient}</p></a></h6>
            </td>
            <td className="price" data-title="">
                <h4 className="text-body">₹{product.price}</h4>
            </td>
            <td className="text-center detail-info" data-title="">
                <h4 className="text-body">x{product.quantity}</h4>
            </td>
            <td className="price" data-title="">
                <h4 className="text-brand">₹{product.subtotal}</h4>
            </td>
        </tr>
    ))}
</tbody>


            </table>
        </div>
        <div class="divider-2 mb-30"></div>
       
    </div>
    <div class="col-lg-4">
        <div class="border p-md-4 cart-totals ml-30">
       
            <div class="table-responsive">
                <table class="table no-border">
                <tbody>
     
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Subtotal</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h4 class="text-brand text-end">₹ {Number(process && process.data && process.data.total).toFixed(2)}</h4>
                    </td>
                </tr>
              
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Shipping</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h5 class="text-heading text-end">₹{Number(process && process.data && process.data.shipping).toFixed(2)}</h5>
                    </td>
                </tr>
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Coupon Discount</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h5 class="text-heading text-danger text-end">- ₹{Number(process && process.data && process.data.offer).toFixed(2) || 0.00}</h5>
                    </td>
                </tr>


                {process && process.data && process.data.gift > 0 ? (<> <tr>
                             <td class="cart_total_label">
                             <h6 class="text-muted">GiftBox</h6>
                             </td>
                             <td class="cart_total_amount">
                                 <h4 class="text-heading text-end">₹{Number(process && process.data && process.data.gift).toFixed(2)}</h4>
                             </td>
                         </tr></>):(<></>)}
                <tr>
                    <td scope="col" colspan="2">
                        <div class="divider-2 mt-10 mb-10"></div>
                    </td>
                </tr>
                <tr>
                    <td class="cart_total_label">
                        <h6 class="text-muted">Total</h6>
                    </td>
                    <td class="cart_total_amount">
                        <h4 class="text-brand text-end">₹{Number(process && process.data && process.data.grandtotal).toFixed(2) || 0.00}</h4>
                    </td>
                </tr>
            
    
    </tbody>
                </table>

                <h5>Shipping Details</h5>
               
               <div class="tab-pane fade active show mt-15"  id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <div class="loop-grid loop-list  mb-50" >
                   <article class="wow fadeIn animated hover-up mb-30 animated">
                     <div class="entry-content-2 pl-50">
                     <span>Customer name : <span>{process && process.data && process.data.name}</span></span><br></br>
               
                       <span>Order Id : <span>{process && process.data && process.data.id}</span></span><br></br>
               
                        <ul class='order-summery'>
                          <li>
                            <span>Placed on: {process && process.data && process.data.date}</span>
                          </li>
                    
                         
                    
                        </ul>
                         <p class="post-exerpt">Address: {process && process.data && process.data.buiulding} {process && process.data && process.data.street}</p>
                         <p class="post-exerpt">city: {process && process.data && process.data.city} </p>
                         <p class="post-exerpt">state: {process && process.data && process.data.state} </p>
                         <p class="post-exerpt">Pincode: {process && process.data && process.data.pincode} </p>
                         <p class="post-exerpt mb-40">Phonenumber: {process && process.data && process.data.phonenumber} </p>
                     </div>
                    </article>
                 </div>

                 <h5>Order Tracing</h5>

<div class="loop-grid loop-list  mb-50" >
  <article class="wow fadeIn animated hover-up mb-30 animated">
    <div class="entry-content-2 pl-50">
      <span>Order Id : <span>{ordertracking && ordertracking.data && ordertracking.data.order_id}</span></span><br></br>

       <ul class='order-summery'>
        
       <li>
                          {ordertracking && ordertracking.data && ordertracking.data.status.map((statusObj, index) => (
                              
    <p key={index} className="post-excerpt">
      <span>Placed on: {statusObj.order_time} <br></br>
        Status: {statusObj.status}
        </span>
    </p>
   
))}
                          </li>
   
        
   
       </ul>
       

    </div>
   </article>
</div>



                 <HomeComponent id="pdf-content"/>
				  {process && process.data && process.data.order_status === "paid" ? (
                 <button
                onClick={handleDownloadPDF}
      className={`btn4 btn-primary`}
      style={{  marginLeft:'100px'}}
    >
     Download Invoice
				  </button>):''}
               </div>
            </div>
          </div>
    </div>
</div>
            )}
        

</div>
    </main>

  <Footer />

  </div>
  </>
  )
}

export default Track;