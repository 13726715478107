import React from 'react';
import Header from './headerpage';
import Footer from './footer';
import Slider from './slider';


function Terms () {
    return [
        <>
        <div class="sidebars">
          <a href="#">
            <h3 class="bar-content">Arivom Products</h3>
          </a>
      </div>
      <div class="main-bar">
    
        <Header  />
        <main class="main pages">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/offline' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                     <span></span> Terms of Use
                </div>
            </div>
        </div>
        <div class="page-content pt-50">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 col-lg-12 m-auto">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="single-page pr-30 mb-lg-0 mb-sm-5">
                                    <div class="single-header style-2">
                                        <h2>Arivomkadai Terms and Conditions Policy</h2>
                                    </div>
                                    <div class="single-content mb-50">
                                        <h4>Welcome to Arivomkadai.  These terms and conditions outline the rules and regulations for the use of www.arivomkadai.com</h4>
                                        <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use www.arivomkadai.com  if you do not accept all of the terms and conditions stated on this page.</p>

                                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: Client, You and Your refers to you, the person accessing this website and accepting the Company’s terms and conditions. The Company, Ourselves, We, Our and Us, refers to our Company. Party, Parties, or Us, refers to both the Client and ourselves, or either the Client or ourselves</p>
                                        <p>All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of (Address).</p>
                                       <p>Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                                        <h3>Cookies</h3>
                                        <p>As on June 2024 we are not using any cookies. But in future (Year:2025) we may employ the use of cookies. By using Arivomkadai’s website you consent to the use of cookies in accordance with Arivomkadai’s privacy policy. Most of the modern day interactive websites use cookies to enable us to retrieve user details for each visit.</p>
                                        <p>Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate / advertising partners may also use cookies.</p>
                                        <h3>
                                        License
                                        </h3>
                                        <p>Unless otherwise stated, Arivomkadai and/or its licensors own the intellectual property rights for all material on www.arivomkadai.com.</p>
                                        <p>All intellectual property rights are reserved. You may view and/or print pages from www.arivomkadai.com  for your own personal use subject to restrictions set in these terms and conditions.</p>
                                       <p>The Name “ARIVOMKADAI” is Trademark Registered and the same cannot be used by others for any other business or promotional activities.</p>
                                       <p>You must not:</p>
                                       <li>Republish material from www.arivomkadai.com</li>
                                       <li>Sell, rent or sub-license material from www.arivomkadai.com </li>
                                       <li>Reproduce, duplicate or copy material from www.arivomkadai.com </li>
                                       <li>Redistribute content from Arivomkadai unless content is specifically made for redistribution </li>
                                       <h3>Modification</h3>
                                       <p>We reserve the right to modify any of the terms and conditions contained      in this
                                        Agreement at any time and in our sole discretion by posting a change notice, revised Agreement, or revised Program Policy on www.arivomkadai.com 
                                        If any modification is unacceptable to you, your only recourse is to terminate this
                                        agreement in accordance with section 6
                                        </p>

                                        <h3>Shipping Policy</h3>
                                        <p>
  At ArivomKadai, we strive to provide you with the best shopping experience possible, including efficient and reliable shipping. Here’s what you need to know about our shipping policy:
</p>
<ol>
<li>Processing Time:
 <ul><li>Once your order is placed, we typically process it within 1-2 business days. During peak periods, processing times may be slightly longer, but we will keep you updated on the status of your order.</li></ul>
</li>
<li>Shipping Time:
<ul><li>Our standard shipping timeframe is between 5 to 10 business days from the date your order is processed. Please note that this estimate may vary depending on your location and any potential delays with our shipping partners.</li></ul>
</li>
<li>Shipping Costs:
<ul><li>Shipping costs are calculated based on the size, weight, and destination of your order. You will see the shipping charges at checkout before you finalize your purchase.</li></ul>
</li>
<li>Order Tracking:
<ul><li>Once your order has been shipped, we will provide you with a tracking number so you can monitor its progress. You can track your order on our website or through the carrier's tracking system.</li></ul>
</li>
<li>Shipping Locations:
<ul><li>We currently ship to India. If you are outside of this area, please contact us directly to discuss shipping options.</li></ul>
</li>
<li>Delivery Issues:
<ul><li>If you experience any issues with your delivery, such as missing or damaged items, please contact our customer service team within 7 days of receiving your shipment. We will work diligently to resolve any issues and ensure your satisfaction.</li></ul>
</li>
<li>Holiday and Peak Periods:
<ul><li>During holidays and peak shopping periods, shipping times may be extended. We appreciate your patience and understanding during these times.</li></ul>
</li>
</ol>
<p>
If you have any questions about our shipping policy or need further assistance, please don’t hesitate to reach out to our customer service team at <a href="https://arivomkadai.com/support ">https://arivomkadai.com/support </a>.

Thank you for shopping with ArivomKadai!
</p> 
                                        <h3>Refund and Replacement</h3>
                                        <p>We will replace the goods to customers and only if they returned the goods to us
                                        within seven working days. The refund is issued only when stocks on the same product, brand, size, shape, colour or etc., is not available with us.
                                        Some products in the Arivomkadai.com (Arivom Products section) are not returnable due to their nature or other reasons. 
                                        </p>
                                        <h3>Refund Policies</h3>
                                        <ol>
                                            <li>Refund will be initiated only after receiving the original product within 7 working days. If customer delayed to send back the product within 7 working days, Arivomkadai has rights to cancel the refund amount. </li>
                                            <li>Refund is approved only if the purchased items sent back in their original packaging, undamaged, and without any size or form changes</li>
                                            <li>Refund shall be made in Indian Rupees only and shall be equivalent to the Transaction Price received in Indian Rupees.</li>
                                            <li>For electronics payments, refund shall be made through payment facility using NEFT / RTGS or any other online banking / electronic funds transfer system approved by Reserve Bank India (RBI).</li>
                                            <li>Refunds may be supported for select banks. Where a bank is not supported for processing refunds, You will be required to share alternate bank account details with us for processing the refund.</li>
                                            <li>Refund shall be conditional and shall be with recourse available to Arivomkadai in case of any misuse by Buyer.</li>
                                            <li>Prebooking orders (Preorder Tab) on Arivomkadai.com (Arivom Products page) – is non refundable and non- returnable due to its nature. </li>
                                            <li>We may also request you for additional documents for verification.</li>
                                            <li>Refund shall be subject to Buyer complying with Policies.</li>
                                            <li>Refund time usually takes 5 to 7 working days. We appreciate your patience</li>
                                        </ol>
                                        <h3>Following products shall not be eligible for return or replacement:</h3>
                                        <p>a. Damages due to misuse of product;</p>
                                        <p>b. Incidental damage due to malfunctioning of product;</p>
                                        <p>c. Any consumable item which has been used/installed. </p>
                                        <p>d. Products with tampered or missing serial/UPC numbers;</p>
                                        <p>e. Digital products/services;</p>
                                        <p>f. Any damage/defect which are not covered under the manufacturer's warranty</p>
                                        <p>g. Any product that is returned without all original packaging and accessories, including the box, manufacturer's packaging if any, and all other items originally included with the product/s delivered;</p>
                                        <p>h. Jewellery which is 'made to order' on customer's request.</p>
                                        <h3>Cancellation Policy </h3>
                                        <p>The customer can choose to cancel an order any time before it's dispatched. The order cannot be cancelled once it’s packed or dispatched. In some cases, the customer may not be allowed to cancel the order for free, post the specified time and a cancellation fee will be charged.</p>
                                        <p>In case of any cancellation from the seller due to unforeseen circumstances, a full refund will be initiated for prepaid orders. </p>
                                        <p>Arivomkadai reserves the right to accept the cancellation of any order. Arivomkadai also reserves the right to waive off or modify the time window or cancellation fee from time to time.</p>
                                        <h3>Payment</h3>
                                        <p>While availing any of the payment method/s available on the Platform, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to You due to:</p>
                                        <ol>
                                            <li>Lack of authorization for any transaction/s, or</li>
                                            <li>Exceeding the preset limit mutually agreed by You and between "Bank/s", or</li>
                                            <li>Any payment issues arising out of the transaction, or</li>
                                            <li>Decline of transaction for any other reason/s</li>
                                            <p>All payments made against the purchases/services on Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. Platform will not facilitate transaction with respect to any other form of currency with respect to the purchases made on Platform.</p>
                                            <p>Before shipping / delivering your order to you, Seller may request you to provide supporting documents (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment instrument used by you for your purchase. This is done in the interest of providing a safe online shopping environment to Our Users. </p>
                                        </ol>

                                        <h3>Disclaimer</h3>
                                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</p>
                                        <p>Nothing in this disclaimer will:</p>
                                        <li>Limit or exclude our or your liability for death or personal injury resulting from negligence.</li>
                                        <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation.</li>
                                        <li>Limit any of our or your liabilities in any way that is not permitted under applicable law.</li>
                                        <li>Or exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                                        <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer:</p>
                                            <ol>
                                                <li>are subject to the preceding paragraph; and</li>
                                                <li>govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities that arise in contract, tort (including negligence) and for breach of statutory duty.</li>
                                            </ol>
                                            <p>To the extent that the website and the information and suggestions on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                                    </div>
                                </div>   
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
       
        <Footer />
    
        </div>
        </>
    ]
}
export default Terms;