import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Header from './headerpages';
import Footeroffline from './footeroffline';

function Preorder() {
   
const [productData, setproductData] = useState(null);
const [cartItems, setCartItems] = useState([]);
const [preorder, setPreorder] = useState([]);
const [banner, setBanner] = useState('');


const fetchDatabanner = async () => {
  try {
    const response = await axios.get('https://api.arivomkadai.com/onlinebanner/');
    const datas = response.data.data.find(item => item.id === '3');
        setBanner(datas); 
        //console.log(datas);
   
  } catch (error) {   
    console.error("Error fetching data:", error);
  }
};

fetchDatabanner();


useEffect(() => {
const fetchData = async () => {
  try {
    const response = await axios.post('https://api.arivomkadai.com/preorderproduct/');
    const products = response.data.data.filter(item =>item.visibility ==='0');
    setproductData(products);
   // console.log(products);
  } catch (error) {
    
    console.error("Error fetching data:", error);
  }
};

fetchData();

const intervalId = setInterval(() => {
  fetchData();
}, 5000);
return () => clearInterval(intervalId);

}, []);



const openModal = () => {
  const modal = document.getElementById('copyModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('copyModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};


const [warning, setWarning] = useState('no');

const handleYesClick = () => {
    setWarning('yes');
    sessionStorage.removeItem('cartItems');
    closeModal(); // Close the modal after setting the warning
};

const handleNoClick = () => {
    setWarning('no');
    closeModal(); // Close the modal after setting the warning
};


useEffect(() => {
  const storedPreorderItem = JSON.parse(sessionStorage.getItem('cartItems'));
  if(storedPreorderItem == null || storedPreorderItem.length == 0){
        setWarning('yes');
  }
}, []);




const openModalsalready = () => {
  const modal = document.getElementById('copyModalsready');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    
  }
  
  
  
};

const closeModalsalready = () => {
  const modal = document.getElementById('copyModalsready');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};


const addToCart = async (productId) => {
 
 
  // Logic based on the warning variable (example scenario)
  // Assuming 'warning' is a global variable indicating user's choice
  if (warning === 'yes') {
      // Proceed with normal cart addition logic
      sessionStorage.removeItem('cartItems');
      // Get the existing cart items from session storage
      const existingpreorderItems = JSON.parse(sessionStorage.getItem('preorder')) || [];
     

      // Check if the product is already in the cart
      const existingItemIndex = existingpreorderItems.findIndex(item => item.id === productId);
      const product = productData && productData.filter(items => items.p_id === productId);

      if (existingItemIndex !== -1) {
        openModalsalready(); 
          // If the product is already in the cart, update its quantity
         // existingpreorderItems[existingItemIndex].quantity += 1;
      } else {
          // If the product is not in the cart, add it as a new item
          const newItem = {
              id: productId,
              product: product[0].product,
              code: product[0].product_code,
              price: product[0].price,
              quantity: 1,
              image: product[0].image
          };

          // Add the new item to the cart
          existingpreorderItems.push(newItem);
          alert(`${product[0].product} added to cart!`);
      }

      // Store the updated cart items back in session storage
      sessionStorage.setItem('preorder', JSON.stringify(existingpreorderItems));

      // Alert the user that the product was added to the cart
      

      // Clear another item from session storage if it already exists
      
  } else {
    openModal();
  }
};


















  return (
     <>
  
   <div className="sidebars" >
   <a  className="sidebars-on" href="/online-products">
        <h3 className="bar-content">Online Products</h3>
        </a>
    </div>
    
<div class="main-bar">

  <Header  />

  <main class="main mt-bar">
      <section class="home-slider position-relative mb-30 mt-50" style={{minHeight:'90vh'}}>
          <div class="container">
              <div class="home-slide-cover mt-30">
                  <div class="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                      <div class="single-hero-slider single-animation-wrap" style={{backgroundImage: `url(https://api.arivomkadai.com/banner_data/${banner && banner.image})`}}>
                          <div class="slider-content">
                          {productData && productData.length > 0 ? (
  
    // Render content when product exists
    <h1 className="display-1 mb-40">
     {banner && banner.heading}
    </h1>
  ) : (
    // Render "Coming soon" when product does not exist
    <h1 className="display-1 mb-40">
       Coming<br />Soon
    </h1>
  )
}



                              <p class="text-white">{banner && banner.text}</p>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </section>


      <div class="modal fade custom-modal" id="copyModalsready" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalsalready}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">{productData && productData.length > 0 ? (productData && ( productData[0].product )):(<></>)}is already in cart</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      <div class="modal1 fade custom-modal" id="copyModal" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white" align="center">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Warning</h4>
                                                                            <p class="mb-30">Your cart contains items from Regular Products. Would you like to reset your cart for adding items from Pre-Order Products?</p>
                                                                        </div>
                                                                        <button className="button mr-10 mt-10" onClick={handleYesClick}><i className="fa-regular fa-copy"></i> Yes</button>
                                                                        <button className="button mr-10 mt-10" onClick={handleNoClick}><i className="fa-regular fa-copy"></i> No</button>
                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>

      {/* <section class="banners mb-25 " >
        <div class="container">
          <div class="gallery mob-slider">

          { sliderData && sliderData.data && sliderData.data.slice(-20).reverse().map(item => (
            
            <div className="cols" key={item.p_id}>
          

                  <div class="image" key={item.p_id}>
                   <a href={`preorderproduct?id=${item.p_id}`}><img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt={item.product}  /> </a> 
                  </div>


            </div>

          ))}
           

          </div>
        </div>
      </section> */}


{/* 
<section class="banners mb-25 " >
          <div class="container">
            <div class="gallery mob-slider">

            { sliderData && sliderData.data && sliderData.data.map((array, index) => (
              
              <div className="cols" key={index}>
                {array.map((item, itemIndex) => (

                    <div class="image" key={itemIndex}>
                       <a href={`preorderproduct?id=${item.id}`}><img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt={item.product}  /> </a> 
                
                      </div>

                ))}

              </div>

            ))}
             

            </div>
          </div>
        </section> */}
    
      <section class="product-tabs section-padding position-relative ">
          <div class="container">
              <div class="section-title style-2 wow animate__animated animate__fadeIn ">
                  <h3>Popular Products</h3>
              </div>
           
              <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                      <div class="row product-grid-4">
                      
                      {productData && productData.slice(-20).reverse().map((product, indexs) => (
                          <div class="col-lg-3 col-xl-1-5 col-md-4 col-12 col-sm-6" key={product.p_id}>
                              <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                  <div class="product-img-action-wrap">
                                      <div class="product-img product-img-zoom">
                                          <a href={`preorderproduct?id=${product.p_id}`}>
                                              <img class="default-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                              <img class="hover-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                          </a>
                                      </div>
                                  </div>
                                  <div class="product-content-wrap">
                                     
                                      <h2><a href={`preorderproduct?id=${product.p_id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a></h2>
                                     
                                     
                                      <div class="product-card-bottom">
                                          <div class="product-price">
                                              <span>₹ {product.price}</span>
                                          </div>
                                          <div class="add-cart">
                                              <a class='add'  onClick={() => addToCart(product.p_id)}  ><i class="fi-rs-shopping-cart mr-5"></i>Add Cart </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      
                          ))}
                        
                      </div>
                
                  </div>
                 
              </div>
           
          </div>
      </section>
     
  </main>

    <Footeroffline />

    </div>
    </>
  )
}
export default  Preorder;

